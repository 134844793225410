// import PublicisCommon from 'publicis-common';
import config from '@/api/config'
const { $cookies, $sessionStorage } = $PCommon
const actions = {
  // 更新当前用户信息
  updateUserInfo({ commit }, userInfo) {
    commit('updateUserInfo', userInfo)
  },
  /**
   * 菜单收起/展开
   */
  updateIsCollapse({ commit }, isCollapse) {
    commit('updateIsCollapse', isCollapse)
  },
  updateLang({ commit }, lang) {
    commit('updateLang', lang)
  },
  updataManagerStatus({ commit }, managerStatus) {
    commit('updataManagerStatus', managerStatus)
  },
  clearUserInfo({ commit }) {
    $cookies.remove(config.COOKIE_TOKEN)
    $sessionStorage.removeItem(config.USER_TOKEN_KEY)
    commit('updateUserInfo', null)
  },
  updataAppLinkList({ commit }, appLinkList) {
    commit('updataAppLinkList', appLinkList)
  },
  updataLang({ commit }, lang) {
    commit('updataLang', lang)
  },
  updataAgencyList({ commit }, agencyList) {
    commit('updataAgencyList', agencyList)
  },
  updataClientList({ commit }, clientList) {
    commit('updataClientList', clientList)
  },
}

export default actions
