<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    const lang = this.$localStorage.getItem('lang');
    if (lang) {
      this.$i18n.locale = lang;
    }
  },
}

</script>

<style lang="scss">
#app {
  height: 100%;
}
</style>
