const { VUE_APP_BASEURL } = process.env
export default {
  /**
   *  获取iris列表
   */
  irisMappinglist(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/iris-mapping/list`, { params: data })
  },
  /**
   *更新iris
   */
  updataIrisMapping(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/iris-mapping/update`, params)
  },
}
