const { VUE_APP_BASEURL} = process.env;
export default {
  /**
   * 问卷列表
   */
  getSurveyList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/survey/list`,{params: data});
  },
  /**
   * 新增问卷
   */
  addSurvey(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/survey/add`,params); 
  },
  /**
   * 问卷删除
   */
  delSurvey(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/survey/del`,params); 
  },
  /**
   * 问卷详情
   */
  detailsSurvey(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/survey/details`,{params: data});
  },
  /**
   * 问卷编辑
   */
  editSurvey(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/survey/upd`,params); 
  }
}