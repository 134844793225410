const { VUE_APP_BASEURL} = process.env;
export default {
    /**
   *  获取行业列表
   */
  getIndustry(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/data-access/industry`,params);
  },
  /**
   * 获取行业下的品牌
   */
  getIndustryBrand(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/data-access/industry-brand`,params);
  },
  /**
   * 提交行业品牌
   */
  confirmIndustry(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/data-access/industry-confirm`,params);
  }
}