<template>
  <div>
    <el-card class="mglass carousel-bg appCard" v-loading="loading">
      <div class="carousel-explain" v-if="isCommonData">
        <div class="dpFlex AIC">
          <ul class="other-make">
            <li
              class="carousel-favourite"
              @click="changeTeam(index)"
              :class="{ active: activeIndex === index, JCC: index == 1 }"
              v-for="(item, index) in dataMsg"
              :key="index"
            >
              <div class="favoutriteBox">
                <i
                  class="poc-size14 iconfont icon-oneui-star-null"
                  style="margin-right: 6px"
                  v-if="index == 1"
                ></i>
                <span class="title">{{ item.title }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="carousel-explain" v-else>
        <span class="title">{{ dataMsg.title }}</span>
        <el-tooltip placement="top-end" popper-class="carousel">
          <div slot="content">
            <div class="carousel-mask" :class="{ widthL: widthL }">
              <strong style="font-weight: 600">{{ dataMsg.contentTitle }}</strong>
            </div>
            <span style="display: block; width: 220px">{{ dataMsg.contentMain }}</span>
          </div>
          <i class="el-icon-question carousel-explain-hover"></i>
        </el-tooltip>
        <!-- <i class="el-icon-question carousel-explain-hover ">
            <div class="carousel-mask " :class="{'widthL':widthL}"> <strong>{{dataMsg.contentTitle}}</strong>{{dataMsg.contentMain}}</div>
          </i> -->
      </div>
      <div class="carousel-flex" :class="{ bigImg: className == 'bigImg' }">
        <i
          class="iconfont icon-oneui-carouse-left imgLeft"
          :class="{ showToggle: carouselIndex > 0 }"
          @click="imageClick('left')"
        ></i>
        <el-carousel
          ref="cardShow"
          trigger="click"
          arrow="never"
          indicator-position="none"
          :class="{ noUl: showIndicatorPosition == 'none' }"
          @change="changeCarousel"
          :initial-index="carouselIndex"
          :autoplay="false"
          :loop="false"
          :height="carouseHeight"
        >
          <el-carousel-item v-for="(numItem, index) in appDataList" :key="index">
            <div class="carousel-box">
              <div
                class="carousel-item"
                @click="goProjectDetail(item)"
                v-for="(item, itemIndex) in numItem"
                :key="itemIndex"
              >
                <div class="carousel-img">
                  <img class="" :src="item.iconimgUrl || item.snapshots" />
                </div>
                <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom">
                  <span class="carousel-title">{{ item.name }}</span>
                </el-tooltip>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <i
          class="iconfont icon-oneui-carouse-right imgRight"
          :class="{ showToggle: carouselIndex < appDataList.length - 1 }"
          @click="imageClick('right')"
        ></i>
      </div>
    </el-card>
  </div>
</template>
<script>
import getAllData from '@/utils/getApplist'
export default {
  name: 'carouselTmp',
  props: {
    type: {
      type: String,
      default: 'home',
    },
    className: {
      type: String,
      default: '',
    },
    appList: {
      type: Array,
      default: [],
    },
    widthL: {
      type: Boolean,
      default: false,
    },
    dataMsg: {},
    isCommonData: {
      type: Boolean,
      default: false,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.iconImgBox = getAllData()
  },
  data() {
    return {
      carouseHeight: '90px',
      carouselIndex: 0,
      activeIndex: 0,
      appDataList: [],
      iconImgBox: [],
      loading: false,
      showIndicatorPosition: 'outside',
    }
  },
  watch: {
    typeId: {
      immediate: false,
      deep: true,
      handler: function () {
        this.getNewList(this.typeId)
      },
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      if (this.isCommonData) {
        if (this.activeIndex == 0) this.getTeamList()
        else this.getFavorList()
      } else this.getNewList(this.typeId)
    },
    changeCarousel(v) {
      this.carouselIndex = v
    },
    imageClick(val) {
      if (val == 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    goProjectDetail(item) {
      this.$emit('showProductDrawer', item)
    },
    changeTeam(index) {
      this.carouselIndex = 0
      this.activeIndex = index
      this.appDataList = []
      if (this.activeIndex == 1) this.getFavorList()
      else this.getTeamList()
    },
    // 获取新的前三种列表
    async getNewList(typeId) {
      this.loading = true
      let { status, data } = await $Api.project.getListByType(typeId)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data !== null) {
          data.map((iItem) => {
            iItem.map((jItem) => {
              this.iconImgBox.map((appItem) => {
                if (appItem.id == jItem.id) {
                  jItem.iconimgUrl = appItem.iconimgUrl
                }
              })
            })
          })
          this.appDataList = data
          if (this.appDataList.length == 1) this.showIndicatorPosition = 'none'
        }
        this.loading = false
      }
    },
    // 获取常用列表
    async getFavorList() {
      this.loading = true
      let { status, data } = await $Api.project.getListMyFavor()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data !== null) {
          data.map((iItem) => {
            iItem.map((jItem) => {
              this.iconImgBox.map((appItem) => {
                if (appItem.id == jItem.id) {
                  jItem.iconimgUrl = appItem.iconimgUrl
                }
              })
            })
          })
          this.appDataList = data
          if (this.appDataList.length == 1) this.showIndicatorPosition = 'none'
        }
        this.loading = false
      }
    },
    // 获取团队列表
    async getTeamList() {
      this.loading = true
      let { status, data } = await $Api.project.getListByTeam()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data !== null) {
          data.map((iItem) => {
            iItem.map((jItem) => {
              this.iconImgBox.map((appItem) => {
                if (appItem.id == jItem.id) {
                  jItem.iconimgUrl = appItem.iconimgUrl
                }
              })
            })
          })
          this.appDataList = data
          if (this.appDataList.length == 1) this.showIndicatorPosition = 'none'
        }
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.carousel-flex.bigImg .carousel-box {
  grid-template-columns: repeat(10, 1fr);
}
</style>
