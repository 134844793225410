<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="42%"
      top="15%"
      :title="researchQuestData.surveyName"
      :close-on-click-modal="false"
      class="question-detail-dialog"
      :class="otherClass"
      @close="closed"
    >
        <div class="launchContent fontB6">
          <ul class="question">
            <li>{{researchQuestData.description}}</li>
          </ul>
      <!-- <span class="green">{{researchQuestData.surveyName}}</span> -->
      <!-- <span>{{researchQuestData.description}}</span> -->
      <!-- {{questionnaireContent}} -->
      </div>
      <div slot="footer" >
        <el-button :class="{'btn-black': otherClass=='home'}" class=" marginR20" @click="closed" >{{$t('dc:稍后填写')}}</el-button>
        <el-button :class="(otherClass=='home')?'btn-green': 'btn-black'" class=" " @click="submit">{{$t('dc:马上填写')}}</el-button>
      </div>
      
    </el-dialog>
  </div>
</template>
<script>
import config from '@/api/config';
export default {
  name: 'applyDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    content: String,
    className: String,
    noClass: String,
    researchQuestData:{}
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
    
  },
  data() {
    return {
      show: this.visible,
      questionnaireTitle: this.$t("dc:问卷调查"),
      questionnaireContent:`${this.$t("dc:请您花几分钟宝贵的时间通过单击下面的链接提供一些有关")} Trace - Media Spending ${this.$t("dc:的反馈？")}`,
      
      // this.$t(
      //   "dc:能否请您花几分钟宝贵的时间,单击以下链接给SSO系统一些反馈？"
      // ),
      otherClass: this.className,
    
    }
  },
  methods: {
    submit() {
      window.open(this.researchQuestData.url)
      this.readMassageOver({messageId:this.researchQuestData.messageId})
      this.$emit('confromQuestion');
    },
    readMassageOver(params) {
        $Api.user.readMessage(params);
    },
    closed() {
      this.$emit('closeQuestion')
      this.show = false
    }
  }
}
</script>
<style lang="scss" >
.home.question-detail-dialog .launchContent{
  ul.question {
            background: transparent;
            max-height: 35px;
            height: 35px;
            // padding: 8px;
            // width: 415px;
            overflow-y: scroll;
            // overflow-x: hidden;
            display: block;
            color: #fff;
            border: none;
            padding:0;
            padding-right: 6px;
        }
        li {
            white-space: pre-line;
            width: 100%;
            line-height:1.2;
            text-align: left;
            // background: rgba(255, 255, 255, 0.2);
            margin-bottom: 12px;
            border-radius: 8px;
            div{
                font-weight: 500;
            }
            span{
              white-space: pre-line;
              font-weight: 300;
            }
        }
        ul::-webkit-scrollbar {/*滚动条整体样式*/

          width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/

          height: 1px;

          }

          ul::-webkit-scrollbar-thumb {/*滚动条里面小方块*/

          border-radius: 4px;

          -webkit-box-shadow: inset 0 0 5px rgba(74, 227, 13, 1);;

          background: rgba(74, 227, 13, 1);

          }

        ul::-webkit-scrollbar-track {/*滚动条里面轨道*/

          // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1);

          border-radius: 4px;

          // background: rgba(102, 104, 107, 1);
          background: transparent;

          }
} 

</style>


