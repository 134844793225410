const { VUE_APP_BASEURL} = process.env;
export default {
  /**
   * 获取ModuleList列表
   */
  getModuleList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/module-list`,{params: data});
  },
  /**
   * 添加模块
   * @param {*} data 
   */
  addModule(params){
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/access/add-module`,params);
  },
  /**
   * 编辑模块
   * @param {*} data 
   */
  editModule(params){
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/access/updModule`,params);
  },
  /**
   * 删除模块
   */
  delectModule(params){
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/access/delete-module`,params);
  }
}