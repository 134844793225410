<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="40%"
      top="10%"
      :append-to-body="true"
      :close-on-click-modal="false"
      :class="homeClass"
      class="history-announcement-dialog"
      :title="$t('dc:公告列表')"
      @close="closed"
    >
      <div class="launchContent fontB6">
        <ul>
              <li v-for="item in historyDataList" >
                <div class="title">
                  <span>{{item.launchTime}}</span>
                  <span>{{item.version}}</span>
                </div>
                <div>
                  <!-- <div class="markdown-body" v-highlight v-html="$options.filters.changeData(item.content)"></div> -->
                  <span >{{item.content}}</span>
                </div>
              </li>
          </ul>
       
      </div>
    <div slot="footer" >
      <el-pagination
      @current-change="pageChange"
      :current-page.sync="historyParams.pageNum"
      :page-size="historyParams.pageSize"
      layout="prev, pager, next, jumper"
      :total="historyParams.navigatePage">
    </el-pagination>
      <!-- <el-button type="info" :class="prePageSize !== 0?'btn-black':''" :disabled="prePageSize === 0" @click="goPrePage">{{$t('dc:上一页')}}</el-button>
      <el-button type="info"  :class="nexPageSize !== 0?'btn-black':''" :disabled="nexPageSize === 0" @click="goNextPage">{{$t('dc:下一页')}}</el-button> -->
    </div>
    </el-dialog>

  </div>
</template>
<script>
import config from "@/api/config";
import marked from 'marked'
export default {
  name: 'historyDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    homeClass:{
      type:String,
      default:''
    }
  },
  filters: {
    changeData: (val) => {
      return marked.parse(val)
    }
  },
  components: {
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },
  data() {
    return {
      show: this.visible,
      token: this.$sessionStorage.getItem(config.USER_TOKEN_KEY),
      otherClass: 'home',
      historyDataList:[],
      historyParams: {
        appName: this.$PROJECT_CODE,
        pageNum:1,
        pageSize:1,
        navigatePage:0
      },
      nexPageSize:0,
      prePageSize:0
      // showPage: this.showNextPage
    }
  },
  created() {
    this.getAnnouncementHistory(this.historyParams) 
  },
  mounted() {
     
  },
  methods: {
     // 获取弹窗历史
     async getAnnouncementHistory(params){
      let {status,data} = await $Api.announcement.historyAnnouncement(params) 
      if(status === this.$RESPONSE_STATUS.SUCCESS) {
        this.historyDataList = data.list
        this.historyParams.navigatePage = data.navigateLastPage
        // this.nexPageSize = data.nextPage
        // this.prePageSize = data.prePage
      }
    },
    pageChange(v) {
      this.historyParams.pageNum = v
      this.getAnnouncementHistory(this.historyParams) 
    },
    goPrePage() {
      this.historyParams.pageNum = this.prePageSize
      this.getAnnouncementHistory(this.historyParams) 
    },
    goNextPage(){
      this.historyParams.pageNum = this.nexPageSize
      this.getAnnouncementHistory(this.historyParams) 
    },
    closed() {
        this.$emit('close')
        this.show = false
    }
  }
}
</script>
<style lang="scss" >
// .launchContent li,.launchContent ol{
//   list-style:auto;
// }
  .launchTile{
      display: block;
      color: #000;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
  }
.launchContent .markdown-body{
  margin-left: -24px;
}
  .launchContent{
      // width: 400px;
      // overflow: hidden;
      ul {
          background: rgba(244, 244, 244, 1);
          max-height: 400px;
          padding: 8px;
          // width: 415px;
          overflow-y: scroll;
          // overflow-x: hidden;
          display: block;
          border: 1px solid #CFD2D8;
      }
      li {
          width: 100%;
          text-align: left;
          // background: rgba(255, 255, 255, 0.2);
          margin-bottom: 12px;
          border-radius: 8px;
          // div{
          //     font-weight: 500;
          // }
          div.title span{

            font-size: 12px;
            font-weight: 700;
          }
          span{
            white-space: pre-line;
            font-weight: 300;
            display: inline-block;
            padding: 0 20px 0 0px;
            word-break:keep-all; 
          }
      }
      ul::-webkit-scrollbar {/*滚动条整体样式*/

        width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/

        height: 1px;

        }

        ul::-webkit-scrollbar-thumb {/*滚动条里面小方块*/

        border-radius: 4px;

        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);

        background: #535353;

        }

      ul::-webkit-scrollbar-track {/*滚动条里面轨道*/

        // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1);

        border-radius: 4px;

        // background: #EDEDED;
        background: transparent;

        }
      // li:hover{
      //     cursor: pointer;
      //     background: rgba(89, 226, 0, 1);
      //     div{
      //         color: #000;
      //         font-weight: 600;
      //     }
      // }
  }
</style>


