<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="580px"
      top="14%"
      :close-on-click-modal="false"
      class="access-change-password-dialog home"
      :title="$t('dc:修改密码')"
    >
      <el-form
        :model="resetDateForm"
        ref="resetDateForm"
        :rules="rulesList"
        label-position="right"
        class="demo-dynamic"
      >
        <el-form-item label-width="220px" :label="$t('dc:用户名称')" class="">
          <el-input
            :disabled="showName"
            v-model="resetDateForm.accountname"
            maxlength="100"
            show-word-limit
          ></el-input>
          <!-- <span v-show="showName" class="el-form-item__error fontS12">Please input valid format;</span> -->
        </el-form-item>
        <el-form-item
          label-width="220px"
          prop="password"
          :label="$t('dc:新密码')"
          class=""
          required
        >
          <el-input
            class="input-black"
            show-password
            v-model="resetDateForm.password"
            maxlength="100"
            :placeholder="$t('dc:请输入密码')"
            show-word-limit
          ></el-input>
          <!-- <span v-show="showPossword" class="el-form-item-other fontS12">The password is no less than 8 characters and no more than 20 characters.</span> -->
        </el-form-item>
        <el-form-item
          label-width="220px"
          prop="confirmPassword"
          :label="$t('dc:确认新密码')"
          class=""
          required
        >
          <el-input
            class="input-black"
            show-password
            v-model="resetDateForm.confirmPassword"
            maxlength="100"
            :placeholder="$t('dc:请输入密码')"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" style="text-align: right">
        <el-button class="btn-black" @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-green" @click="submitForm('resetDateForm')">{{
          $t('dc:确认')
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="30%"
      class="access-change-password-dialog"
      :visible.sync="innerVisible"
      :show-close="false"
    >
      <div>{{ $t('dc:密码已重置，请重新登录') }}</div>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-black" type="primary" @click="innerVisible = false">{{
          $t('dc:提交')
        }}</el-button>
      </div>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="title"
      :className="classNameOne"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>

<script>
import config from '@/api/config'
import createRsa from '@/utils/encryption'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'accessDialog',
  components: {
    UsuallyDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: String,
    typeOptions: {
      type: Array,
      default: () => [],
    },
    submitData: {
      type: Array,
      default: () => [],
    },
    accountname: String,
    max: Number,
  },
  data() {
    var nameRule = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else if (!new RegExp('[A-Z]').test(value)) {
        callback(new Error(this.$t('dc:请按规则输入')))
      } else {
        callback()
      }
    }
    var passwordRule = (rule, value, callback) => {
      var Regx = /^[A-Za-z0-9*]+$/
      if (value === '' || 8 > value.length || 21 < value.length) {
        this.showPossword = false
        callback(new Error(this.$t('Please input valid format')))
      } else if (!new RegExp(Regx).test(value)) {
        this.showPossword = false
        callback(new Error(this.$t('dc:仅支持数字、大小写字母')))
      } else {
        this.showPossword = true
        callback()
      }
    }
    var confirmPass = (rule, value, callback) => {
      var Regx = /^[A-Za-z0-9*]+$/
      if (value === '' || 8 > value.length || 21 < value.length) {
        callback(new Error('Please input valid format'))
        this.showPossword = false
      } else if (!new RegExp(Regx).test(value)) {
        this.showPossword = false
        callback(new Error(this.$t('dc:仅支持数字、大小写字母')))
      } else if (value !== this.resetDateForm.password) {
        this.showPossword = false
        callback(new Error(this.$t('dc:新密码与确认密码不匹配')))
      } else {
        this.showPossword = true
        callback()
      }
    }
    return {
      loading: false,
      show: this.visible,
      showName: true,
      showPossword: true,
      innerVisible: false,
      show4Dialog: false,
      classNameOne: 'home',
      title: this.$t('dc:提醒'),
      content: this.$t('dc:您确定要修改密码吗？'),
      resetDateForm: {
        accountname: this.accountname,
        password: '',
        confirmPassword: '',
      },
      rulesList: {
        accountname: [{ validator: nameRule, trigger: ['blur', 'change'] }],
        password: [{ validator: passwordRule, trigger: 'blur' }],
        confirmPassword: [{ validator: confirmPass, trigger: 'blur' }],
      },
    }
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.resetDateForm.accountname = this.userInfo.username
  },
  methods: {
    ...mapActions(['clearUserInfo']),
    onConfirm() {},
    onCancel() {
      this.show = false
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.show4Dialog = true
          // this.$emit('changePassword', this.resetDateForm);
        } else {
          return false
        }
      })
    },
    confrom() {
      this.changePwd()
    },
    async changePwd() {
      let params = {
        userId: this.userInfo.id,
        newPassword: createRsa(this.resetDateForm.password),
        confirmPassword: createRsa(this.resetDateForm.confirmPassword),
      }
      const { status, msg } = await $Api.user.changePwd(params)
      if (status == 0) {
        this.$message({
          showClose: true,
          message: 'The password has been reset,please login again.',
          type: 'success',
        })
        $Api.user.logout()
        this.clearUserInfo()
        this.$emit('changePassword', true)
        this.show = false
      } else {
        this.resetDateForm.password = ''
        this.resetDateForm.confirmPassword = ''
        // this.$message({
        //   showClose: true,
        //   message: msg,
        //   type: "error"
        // });
      }
    },
  },
}
</script>
<style lang="scss">
.marginB50 {
  margin-bottom: 50px !important;
}
</style>
