<template>
  <div class="sso-management chatGpt">
    <poc-container class="sm-container">
      <template slot="logo">
        <img :src="chatGptLogo" style="height: 38px" />
        <ul class="logo-ailist">
          <li
            @click="changeAiId(item)"
            :class="{ active: index == aiActiveId }"
            v-for="(item, index) in aiList"
            :key="item.id"
          >
            {{ item.title }}
          </li>
        </ul>
      </template>

      <template slot="rightGroup">
        <HeaderChatRightGroup slot="rightGroup" />
      </template>
      <div class="chatGpt-main">
        <elTransitionTmp>
          <div class="chatGpt-main-left" v-show="showLeftStatus">
            <div class="addNewChat" @click="addChat">
              <el-button type="primary" style="height: 100%; width: 100%"
                >+ {{ $t('dc:新增会话') }}</el-button
              >
            </div>
            <div class="recording">会话记录</div>
            <el-scrollbar style="height: 100%; background-color: #353535">
              <ul>
                <li
                  :class="{ active: chatLeftActiveId == item.chatId }"
                  v-for="(item, index) in chatLeftData"
                  @click="selectChatLeft(item)"
                >
                  <div class="titleBox">
                    <div class="dpF">
                      <el-tooltip class="item" effect="dark" :content="item.title" placement="top">
                        <i class="poc-size12 iconfont icon-oneui-sso-chatmsg"></i>
                      </el-tooltip>
                      <span class="title">{{ item.title }}</span>
                    </div>
                    <div class="iconBox">
                      <i
                        @click.stop="delChat(item)"
                        :class="{ noDel: !delStatus }"
                        class="chatIcon poc-size12 iconfont icon-oneui-delete"
                      ></i>
                    </div>
                  </div>
                </li>
              </ul>
            </el-scrollbar>
            <i
              v-show="showLeftStatus"
              class="goToLeft el-icon-arrow-left"
              @click.stop="showLeftStatus = false"
            ></i>
          </div>
        </elTransitionTmp>
        <el-container>
          <div class="chatGpt-main-right">
            <div class="explain">
              <div class="explain-bg">
                <div class="explain-money">
                  <div class="chat">
                    <span class="chat-title">{{ chatContent.aTitle }}</span>
                    <span class="chat-name">{{ chatContent.title }}</span>
                  </div>
                  <div class="chatVersion">
                    <div class="spend">
                      <i
                        class="iconfont icon-oneui-eyes-error"
                        style="margin-right: 6px; color: yellow"
                      ></i>
                      <span class="mgL4">{{ $t('dc:剩余流量') }}: </span>
                      <span :class="{ red: chatflow <= 20 }" class="mgL16"> {{ chatflow }}% </span>
                      <span class="green mgL4">{{ chatMoney | formatPoint }}k </span>
                      <span class=""> Words</span>
                    </div>
                    <div class="chatVersion-box">
                      <span class="chatVersion-msg">当前版本:</span>
                      <el-select
                        v-model="chatVersionValue"
                        :placeholder="$t('dc:请选择')"
                        popper-class="chatVersionSelect"
                      >
                        <el-option
                          v-for="item in chatVersionList"
                          :key="item.id"
                          :label="item.value"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
                <div class="explain-box">
                  <div
                    class="warn"
                    v-highlight
                    :style="{
                      height: protocolHeightStatus ? protocolMaxHeight : protocolMinHeight,
                    }"
                  >
                    <ul>
                      <li
                        v-for="(item, index) in warnMsgDataList"
                        :key="index"
                        :class="{ strong: item.strong }"
                      >
                        {{ item.content }}
                      </li>
                    </ul>
                    <!-- <div class="markdown-body" v-highlight v-html="$options.filters.changeData(warnMsg)"></div> -->
                  </div>
                  <!-- <div v-if="time>0 && msgData.messageList.length == 1 " class="lookProtocol">{{$t('dc:请认真阅读使用规范')}} <span class="green">({{ time }})</span></div> -->
                  <i
                    v-show="protocolHeightStatus"
                    @click="protocolHeightStatus = false"
                    class="chatProtocolIcon poc-size16 iconfont iconfont icon-oneui-sso-chat-down"
                  ></i>
                  <i
                    v-show="!protocolHeightStatus"
                    @click="protocolHeightStatus = true"
                    class="chatProtocolIcon poc-size16 iconfont iconfont icon-oneui-sso-chat-up"
                  ></i>
                </div>
              </div>
            </div>

            <el-scrollbar :key="scrollKey" ref="chatGPTScrollbar" style="height: calc(80%)">
              <ul ref="chatMain" class="chatRight">
                <li
                  :class="{ active: item.role == 'assistant' }"
                  v-for="(item, index) in msgData.messageList"
                >
                  <div class="chatBg">
                    <div class="dpF">
                      <div class="chatBox" :class="{ meBorder: item.role == 'user' }">
                        <img
                          class="chatImg"
                          v-if="item.role == 'assistant'"
                          :src="chatImg"
                          alt=""
                        />
                        <span v-if="item.role == 'user'">ME</span>
                      </div>
                      <div class="chatcontent" v-if="item.role == 'user'">
                        <p class="userInput">{{ item.content }}</p>
                      </div>
                      <div class="chatcontent" v-if="item.role == 'assistant'">
                        <div v-if="item.content === ''" class="autoHeight">
                          <i class="el-icon-loading"></i>
                        </div>
                        <div
                          v-else-if="!item.isNew"
                          class="markdown-body"
                          v-highlight
                          v-html="$options.filters.changeData(item.content)"
                        ></div>
                        <!-- <markdown-it-vue  class="md-body" :content="item.content" /> -->
                        <vue-typed-js
                          v-else="item.isNew"
                          v-highlight
                          class="markdownBg"
                          :strings="[$options.filters.changeData(item.content)]"
                          :loop="false"
                          :showCursor="false"
                          :startDelay="10"
                          :typeSpeed="20"
                          :backSpeed="50"
                          @onComplete="doSmth(item)"
                        >
                          <span class="typing"></span>
                        </vue-typed-js>
                        <!-- <div  v-else="item.isNew"  class="markdown-body" v-highlight v-html="$options.filters.changeData(item.content)"></div> -->
                      </div>

                      <div
                        class="iconBox code-data-copy"
                        data-clipboard-action="copy"
                        :data-clipboard-text="item.content"
                      >
                        <el-tooltip effect="dark" :content="$t('dc:复制')" placement="top">
                          <i
                            @click="copyMessage"
                            class="chatIcon poc-size16 iconfont icon-oneui-copy"
                          ></i>
                        </el-tooltip>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </el-scrollbar>
            <i
              v-show="!showLeftStatus"
              class="goToRight el-icon-arrow-right"
              @click.stop="showLeftStatus = true"
            ></i>

            <div class="inputValue">
              <div class="inputValue-box">
                <!-- <makdownEdit class="CKEditor" ref="Editor" v-model="chatContent" @change="callbackChangeEditor"></makdownEdit> -->
                <el-input
                  ref="chatInput"
                  type="textarea"
                  v-model="inputValue"
                  resize="none"
                  :disabled="loading || chatflow == 0"
                  :autosize="{ minRows: 3, maxRows: 3 }"
                  show-word-limit
                  :placeholder="$t('dc:GPT请输入') + `(Shift + Enter = ${$t('dc:换行')})`"
                  @keyup.enter.native="enterMsg"
                >
                </el-input>
                <el-button class="enterKey" type="info" @click="mouseEnterMsg">{{
                  $t('dc:发送')
                }}</el-button>
                <!-- <img class="enterKey" @click="mouseEnterMsg" :src="enterkey" alt=""> -->
              </div>
            </div>
          </div>
        </el-container>
      </div>
    </poc-container>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :dWith="dWith"
      :fontClass="'fontB1'"
      :className="classNameOne"
      @confrom="confrom"
    ></UsuallyDialog>
    <ChatNoDosageDialog
      v-if="show4NoDosageDialog"
      :visible.sync="show4NoDosageDialog"
      :dWith="dWith"
      :fontClass="'fontB1'"
      :className="classNameOne"
      @confrom="confromClose"
      :content="errContent"
    ></ChatNoDosageDialog>
  </div>
</template>
<script>
import chatGptLogo from '@/assets/img/chatGptLogo.png'
import chatImg from '@/assets/img/chatImg.png'
import enterkey from '@/assets/img/enterkey.png'
import MarkdownItVue from 'markdown-it-vue'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
import elTransitionTmp from '@/views/components/elTransitionTmp'
import marked from 'marked'
import HeaderChatRightGroup from '@/components/HeaderChatRightGroup'
import makdownEdit from '@/views/components/makdownEdit'
import ChatNoDosageDialog from '@/views/dialog/chatNoDosageDialog'
import DOMPurify from 'dompurify'
import { sendWebsocket, closeWebsocket } from '@/utils/socket.js'
export default {
  name: 'chatGpt',
  components: {
    HeaderChatRightGroup,
    MarkdownItVue,
    UsuallyDialog,
    ChatNoDosageDialog,
    elTransitionTmp,
    makdownEdit,
  },
  watch: {
    protocolHeightStatus: {
      deep: false,
      immediate: true,
      handler(to) {
        this.scrollKey = new Date().getTime()
        if (!to) {
          clearInterval(this.scrollTimer)
        }
      },
    },
    loading: {
      deep: false,
      immediate: true,
      handler(to) {
        this.scrollKey = new Date().getTime()
        if (!to) {
          this.$nextTick(() => {
            this.$refs.chatInput.focus()
          })
        }
      },
    },
    inputValue: {
      deep: false,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== '') {
          if (newValue.length >= 2048) {
            this.errContent = this.$t('dc:您的对话已超出限制')
            this.show4NoDosageDialog = true
            return (this.inputValue = oldValue)
          }
        }
      },
    },
  },
  data() {
    return {
      chatGptLogo,
      chatImg,
      enterkey,
      show4Dialog: false,
      show4NoDosageDialog: false,
      loading: false,
      showLeftStatus: true,
      classNameOne: 'home',
      delChatValue: {},
      dWith: '388px',
      protocolHeightStatus: true,
      delStatus: true,
      protocolMinHeight: '52px',
      protocolMaxHeight: '310px',
      warnMsg: `1.  Disclaimer: This is a test version using ChatGPT (gpt-3.5-turbo) language model. It's important to note that the system may occasionally generate incorrect or misleading information, as well as produce offensive or biased content. It is not intended to give advice. Therefore, please verify the output before using it.
2.  Kindly comply with the relevant policies and requirements of the Groupe regarding data security and privacy protection obligations. All chat history will be recorded and analyzed for future improvement and compliance monitoring. 
3.  Please refrain from disclosing any business data or confidential information associated with our Groupe or our clients.
4.  Furthermore, avoid disclosing any personal information related to company employees or clients, and exercise caution in preventing data privacy violations. It is also recommended not to upload personal information with cell phone or device ID information.
5.  Prior to utilizing any generated content, please ensure it is compliant with advertising law and free from any intellectual property infringement.
6.  Due to the limitation of the language model, conversations are delivered to the AI engine with a historical conversation limit of 2000 words, which could result in exponential costs. Therefore, please start a new chat if a new context is applicable.`,
      chatLeftData: [],
      content: this.$t('dc:确认删除'),
      errContent: this.$t('dc:您的对话已超出限制'),
      inputValue: '',
      chatMoney: '0',
      time: 0,
      chatflow: 10,
      timer: null,
      scrollTimer: null,
      chatLeftActiveId: '',
      msgData: {
        chatId: null,
        messageList: [],
      },
      chatGPTParams: {
        chatId: null,
        messageList: [],
      },
      chatNewGPTParams: {
        chatId: null,
        messageList: [],
      },
      chatContent: {
        title: 'New Chat',
        aTitle: 'N',
      },
      scrollKey: 0,
      chatCallbackValue: '',
      aiList: [
        {
          title: 'Chat GPT',
          id: 0,
        },
        {
          title: 'MidJoumey',
          id: 1,
        },
        {
          title: 'Stable Diffusion',
          id: 2,
        },
      ],
      aiActiveId: 0,
      chatVersionList: [
        {
          value: 'Chat GPT3.5',
          id: 0,
        },
        {
          value: 'Chat GPT4.0',
          id: 1,
        },
      ],
      chatVersionValue: 0,
    }
  },
  created() {
    this.getAuthorityMsgData()
  },
  computed: {
    warnMsgDataList() {
      return [
        {
          strong: false,
          content: this.$t('dc:责任声明1'),
        },
        {
          strong: false,
          content: this.$t('dc:责任声明2'),
        },
        {
          strong: true,
          content: this.$t('dc:责任声明3'),
        },
        {
          strong: false,
          content: this.$t('dc:责任声明4'),
        },
        {
          strong: false,
          content: this.$t('dc:责任声明5'),
        },
        {
          strong: false,
          content: this.$t('dc:责任声明6'),
        },
        {
          strong: true,
          content: this.$t('dc:责任声明7'),
        },
      ]
    },
  },
  mounted() {
    this.initEvent()
  },
  filters: {
    // marked插件转化成HTML格式
    changeData: (val) => {
      return DOMPurify.sanitize(marked.parse(val))
    },
  },
  methods: {
    doSmth(value) {
      value.isNew = false
      this.loading = false
      this.initScrollData()
      clearInterval(this.scrollTimer)
    },
    initChatData() {
      this.chatNewGPTParams = {
        chatId: null,
        messageList: [],
      }
      this.msgData = {
        chatId: null,
        messageList: [],
      }
    },
    initScrollData() {
      this.$nextTick(() => {
        this.$refs.chatGPTScrollbar.wrap.scrollTop = this.$refs.chatGPTScrollbar.wrap.scrollHeight
      })
    },
    initEvent() {
      this.getUseMoney()
      this.getAllChatList(true)
    },
    changeProtocolHeight() {
      this.protocolHeight = '60px'
    },
    enterMsg(event) {
      if (event.keyCode === 13 && !event.shiftKey) {
        if (this.inputValue == '' || this.loading) return
        this.chatNewGPTParams.messageList = []
        let chatParams = {
          role: 'user',
          content: this.inputValue,
        }
        this.chatNewGPTParams.messageList.push(chatParams)
        this.msgData.messageList.push(chatParams)
        this.askChat(this.chatNewGPTParams)
      }
    },
    mouseEnterMsg() {
      // closeWebsocket()
      // sendWebsocket('ws://121.40.165.18:8800', {},(data) => {
      //           console.log('成功的回调函数, 接收到的data数据： ', data)
      //       },
      //       // websocket连接失败的回调函数
      //       () => {
      //           console.log('失败的回调函数')
      //       })

      // return
      this.chatNewGPTParams.messageList = []
      let chatParams = {
        role: 'user',
        content: this.inputValue,
      }
      this.chatNewGPTParams.messageList.push(chatParams)
      this.msgData.messageList.push(chatParams)
      this.askChat(this.chatNewGPTParams)
    },

    async askChat(params) {
      this.loading = true
      let cmsg = {
        role: 'assistant',
        content: '',
        isNew: false,
      }

      this.inputValue = ''
      this.msgData.messageList.push(cmsg)
      clearInterval(this.scrollTimer)
      this.initScrollData()
      let { status, data, msg } = await $Api.chatGPT.getChatGPTMsg(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.chatNewGPTParams.chatId = data.chatId
        cmsg.content = data.messageList[0].content
        cmsg.isNew = true
        this.getAllChatList(false)
        this.getUseMoney()
        this.startScrollTimer()
      } else {
        this.errContent = this.$t('dc:您的对话已超出限制')
        cmsg.content = msg
        this.show4NoDosageDialog = true
        this.loading = false
        this.initScrollData()
      }
    },
    async getAllChatList(type) {
      let { status, data } = await $Api.chatGPT.getChatList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.delStatus = true
        this.chatLeftData = data
        if (data.length == 0) {
          this.initChatData()
          return
        }
        if (type) {
          this.chatLeftActiveId = this.chatLeftData[0].chatId
          this.getChatDetailById(this.chatLeftData[0].chatId)
        } else {
          if (this.delChatValue.chatId == this.chatLeftActiveId) {
            this.chatLeftActiveId = this.chatLeftData[0].chatId
            this.getChatDetailById(this.chatLeftData[0].chatId)
          }
        }
      }
    },
    async getChatDetailById(id) {
      this.chatNewGPTParams.chatId = id
      this.chatNewGPTParams.messageList = []
      let { status, data } = await $Api.chatGPT.getChatDetail(id)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.msgData = JSON.parse(JSON.stringify(data))
        this.msgData.messageList.unshift({
          role: 'assistant',
          content: this.$t('dc:你好今天我能为您提供什么帮助？'),
        })
        if (data.messageList.length > 1) this.protocolHeightStatus = false
        this.initScrollData()
      }
    },
    async addChat() {
      let { status, data } = await $Api.chatGPT.addNewChat()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.getAllChatList(true)
        this.initChatData()
      }
    },
    async delChatById(id) {
      this.delStatus = false
      let { status, data } = await $Api.chatGPT.delChat(id)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.getAllChatList(false)
      }
    },
    // 点击左侧会话列表
    selectChatLeft(item) {
      this.chatContent = item
      this.chatContent.aTitle = item.title.slice(0, 1)
      this.inputValue = ''
      this.loading = false
      this.chatLeftActiveId = item.chatId
      this.chatNewGPTParams.chatId = item.chatId
      this.chatNewGPTParams.messageList = []
      this.getChatDetailById(item.chatId)
      this.protocolHeightStatus = false
      clearInterval(this.scrollTimer)
    },

    copyMessage() {
      let _this = this
      let clipboard = new this.clipboard('.code-data-copy')
      clipboard.on('success', function (e) {
        _this.$message({
          showClose: true,
          message: 'copy success',
          type: 'success',
        })
        clipboard.destroy()
      })
      clipboard.on('error', function () {
        _this.$message({
          showClose: true,
          message: 'copy error',
          type: 'warning',
        })
      })
    },
    delChat(value) {
      if (!this.delStatus) return
      this.delChatValue = value
      this.show4Dialog = true
    },
    confrom() {
      this.chatLeftData.map((item, index) => {
        if (item.chatId === this.delChatValue.chatId) {
          this.delChatById(this.delChatValue.chatId)
          this.show4Dialog = false
        }
      })
    },
    confromClose() {
      this.show4NoDosageDialog = false
    },
    async getUseMoney() {
      let { status, data } = await $Api.chatGPT.getChatMoney()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        let { cost, remain } = data
        this.chatMoney = cost || 0
        this.chatflow = remain || 0
        this.errContent = this.$t('dc:你的流量用完了')
        if (this.chatflow == 0) this.show4NoDosageDialog = true
      }
    },
    async getAuthorityMsgData() {
      let { status, data } = await $Api.chatGPT.getAuthority()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (!data) {
          this.$router.push({ name: 'login' })
        }
      }
    },
    startScrollTimer() {
      this.scrollTimer = setInterval(() => {
        this.initScrollData()
      }, 100)
    },
    changeAiId(item) {
      this.aiActiveId = item.id
    },
  },
  beforeDestroy() {
    clearInterval(this.scrollTimer)
  },
}
</script>
<style lang="scss">
$-chatLTWidth200: 200px;
$-chatLWidth280: 280px;
$-chatLWidth352: 352px;
$-chatRWidth660: 660px;
$-chatRWidth656: 656px;
$-chatRWidth680: 680px;
$-chatRWidth750: 750px;
$-chatRWidth780: 780px;

.sso-management.chatGpt {
  background: #282a2b;
}

.logo-ailist {
  margin-top: 8px;
  display: flex;
  li {
    font-size: 18px;
    font-weight: 600;
    margin: 0 16px;
    cursor: pointer;
  }

  li.active {
    color: #4ae30d;
    background: #4ae30d;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-reflect: below -4px -webkit-linear-gradient(
        transparent,
        transparent 16%,
        rgba(255, 255, 255, 0.5)
      );
  }
}

.sso-management.chatGpt .el-loading-mask {
  background: rgba(40, 42, 43, 0.95);

  .el-loading-spinner .path {
    stroke: #fff;
  }
}

.spend {
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;

  .green {
    color: #4ae30d;
  }

  .red {
    color: red;
  }
}

.chatGpt-main {
  li,
  ol {
    list-style: auto !important;
  }

  position: fixed;
  top: 60px;
  height: 100%;
  width: 100%;
  display: flex;
  background: #282a2b;

  &-left {
    height: 100%;
    // padding-right: 16px;
    margin-right: 16px;
    background-color: #353535;
    width: $-chatLWidth280;
    position: relative;

    ul {
      margin-bottom: 110px;
    }

    ul li {
      font-size: 14px;
      height: 52px;
      line-height: 52px;
      color: #fff;
      cursor: pointer;

      .titleBox {
        display: flex;
        justify-content: space-between;

        i {
          margin: 0 12px;
        }
      }

      .iconBox {
        // width: 80px;
        margin: 0 8px;

        i {
          margin: 0 6px 0 8px;
        }
      }

      .chatIcon.noDel {
        cursor: not-allowed;
      }

      .chatIcon:hover {
        color: #4ae30d !important;
      }

      .title {
        display: block;
        width: $-chatLTWidth200;
        height: 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    li:hover {
      color: #4ae30d;
      background: #434343;

      .iconfont,
      .iconBox i {
        color: #4ae30d;
      }
    }

    li.active {
      background: #4d4d4d;
    }

    .dpF {
      display: flex;
      align-items: center;
    }

    .goToLeft {
      border: 1px solid #aaaaaa;
      border-radius: 50%;
      padding: 2px;
      position: absolute;
      top: 50%;
      right: -16px;
      font-size: 14px;
      color: #fff;
      opacity: 0;
      cursor: pointer;
    }
  }

  &-left:hover {
    .goToLeft {
      opacity: 1;
    }
  }

  &-right {
    height: 100%;
    width: 100%;
    min-width: $-chatRWidth780;
    margin-bottom: 88px;
    background-color: #353535;
    // position: relative;
    display: flex;
    flex-direction: column;

    ul.chatRight {
      margin: 0 auto;
      background: transparent;
      // margin-bottom: 280px;
    }

    li .chatBg {
      // width: $-chatRWidth780;
      // padding-top: 16px;
      // padding-bottom: 16px;
      padding: 16px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    .lookProtocol {
      text-align: center;
      margin: 0 auto 16px;
    }

    .warn {
      overflow: hidden;
    }

    .chatcontent {
      flex: 1;
      width: $-chatRWidth656;
      margin: -0.5rem 16px 0;
      // margin-left: 28px;
      // margin-right: 16px;
      font-size: 14px;
      color: #fff;
      line-height: 1.4;
      word-wrap: break-word;

      .autoHeight {
        line-height: 52px;

        i {
          font-size: 16px;
        }
      }
    }

    .chatBox {
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      background: #4a8e2e;
      border-radius: 3px;
      border: 1px solid #525252;
    }

    .chatBox.meBorder {
      width: 30px;
      height: 30px;
      background: #000;
      line-height: 28px;
      // border: 1px solid #4AE30D;

      span {
        font-size: 12px;
        font-weight: 600;
        // color: #4AE30D;
      }
    }

    .chatImg {
      display: block;
      width: 26px;
      height: 26px;
      margin: 3px auto;
    }

    .iconBox {
      margin-top: 21px;

      i {
        cursor: pointer;
        color: #fff !important;
      }
    }

    .chatIcon:hover {
      color: #4ae30d !important;
    }

    li.active {
      background: #434343;
    }

    .dpF {
      display: flex;
      flex: 1;
    }
  }

  .goToRight {
    border: 1px solid #aaaaaa;
    border-radius: 50%;
    padding: 2px;
    position: absolute;
    top: 50%;
    left: 8px;
    font-size: 14px;
    color: #fff;
    opacity: 0;
    cursor: pointer;
  }

  &-right:hover {
    .goToRight {
      opacity: 1;
    }
  }

  .inputValue {
    // position: relative;
    // position: absolute;
    display: flex;
    z-index: 20;
    justify-content: center;
    left: 0;
    width: 100%;
    height: 200px;
    bottom: 50px;
    background: #434343;

    .el-textarea__inner {
      right: 15%;
      margin: 16px auto 32px;
      width: 100% !important;
      color: #fff;
      border: 1px solid #6f6f6f;
      // min-height: 90px !important;
      background: #434343;
      caret-color: #4ae30d;
    }

    .el-textarea.is-disabled .el-textarea__inner {
      background: #434343;
    }

    .enterKey {
      position: absolute;
      display: block;
      right: 32px;
      top: 45px;
      color: #000;
      padding: 4px 6px;
      background-image: linear-gradient(#9bff44, #4cb20c);
    }
  }

  .inputValue-box {
    position: relative;
    width: 100%;
    padding: 0 16px;
  }

  .userInput {
    white-space: pre-wrap;
  }

  .addNewChat {
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #919191;
    height: 52px;
    margin: 16px;
    line-height: 52px;
    text-align: center;
    cursor: pointer;

    span {
      color: #4ae30d;
    }

    .el-button.el-button--primary {
      background: #414141;
    }
  }

  .recording {
    padding: 0 16px 16px;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
  }

  .explain {
    height: 188px;
    position: relative;
    // width: $-chatRWidth656;
    // margin: 0 auto;
    font-size: 12px;
    line-height: 1.4;
    color: #aaaaaa;
  }

  .explain-money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: #353535;

    .chat {
      display: flex;
      align-items: center;
    }

    .chat-title {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 14px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: #4ae30d;
      color: #000;
      font-weight: 600;
      margin-right: 16px;
    }

    .chat-name {
      font-size: 14px;
      color: #fff;
      line-height: 1.4;
      word-wrap: break-word;
    }
  }

  .explain-bg {
    width: 100%;
    position: absolute;
    z-index: 2;
    background: #353535;
  }

  .explain-box {
    // width: $-chatRWidth656;
    padding: 16px 66px;
    margin: 0 auto;
    position: relative;
    background-color: #4d4d4d;

    ul li {
      margin: 4px 0;
      line-height: 1.2;
    }

    ul li.strong {
      font-weight: 700;
    }
  }

  .chatVersion {
    display: flex;
    align-items: center;
    .spend span {
      height: 15px;
    }
  }

  .chatVersion-msg {
    line-height: 2.5;
    color: #bababa;
    width: 80px;
  }

  .chatVersion-box {
    margin-left: 16px;
    width: 200px;
    display: flex;

    .el-input__inner {
      color: #fff;
    }

    .el-select .el-input .el-input__inner,
    .el-select .el-input.is-focus .el-input__inner,
    .el-select .el-input__inner:focus {
      border-color: #6d6d6d;
    }
  }

  .chatProtocolIcon {
    position: absolute;
    bottom: 12px;
    right: 16px;
    padding: 1px 2px;
    border-radius: 2px;
    color: #fff;
    cursor: pointer;
  }

  .chatProtocolIcon:hover {
    color: #4ae30d !important;
  }

  .green {
    color: #4ae30d;
  }

  .markdownBg pre code {
    // width: 656px;
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    background: #23241f;
  }

  .mgL4 {
    margin-right: 4px;
  }

  .mgL8 {
    margin-right: 8px;
  }

  .mgL16 {
    margin-right: 16px;
  }
}
</style>
