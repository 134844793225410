<template>
  <div class="header-right-group ">
   
    <el-tooltip effect="dark" :content="$t('dc:首页')" placement="top">
     <el-button type="primary" class="hrg-item" circle @click="goSSOHome"><i class="el-icon-s-home"></i></el-button>
    </el-tooltip>
    <el-tooltip effect="dark" :content="$t('dc:用户中心')" placement="top">
    <el-dropdown trigger="click" class="hrg-item" @command="onCommand">
      <el-button type="primary" circle><i class="el-icon-user-solid"></i></el-button>
      <el-dropdown-menu slot="dropdown"
          class="dropdown-userinfo-popper"
          style="background:rgba(40, 40, 40, 1);color:#fff;">
        <el-dropdown-item command="info" disabled>{{userName}}</el-dropdown-item>
        <el-dropdown-item command="logout" icon="poc-size12 iconfont icon-oneui-logout">{{$t('dc:退出')}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    </el-tooltip>
  
    <el-button type="primary"  @click="switchLang" circle class="hrg-item">
      <span class="switch-lang">{{this.$i18n.locale === 'zh-cn' ? 'En' : '中'}}</span>
    </el-button>
  </div>
</template>

<script>
import config from "@/api/config";

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'HeaderRightGroup',
  props:{
   
  },
  watch: {
   
  },
  components: {
    
  },
  data() {
    return {
      showUser: true,
      showLaunchStatus:false,
      showNextPage:false,
      showLaunchHistoryStatus:false,
      launchDataList:{},
      historyDataList:[],
      nexPageSize :1,
      urlName: 'home',
    
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    userName() {
      return this.userInfo.fullName || '';
    },
  },
  mounted(){
    this.initEvent()
  },
  methods: {
    ...mapActions(['clearUserInfo']),
    initEvent(){
     
    },
    
    /**
     * 到SSO的home页
     */
    goSSOHome() {
      this.$router.push({name: 'home'})
    },
    /**
     * 语言切换：zh-cn | en-us
     */
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === 'zh-cn' ? 'en-us' : 'zh-cn';
      this.upDataLang()
    },
    /**
     * 存储用户语言
     */
    async upDataLang() {
      let {status} = await $Api.user.setLang({appName: this.$PROJECT_CODE,lang: this.$i18n.locale}) 
      if(status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$localStorage.setItem('lang', this.$i18n.locale);
      }
    },
    /**
     * dropdown项点击
     */
    onCommand(v) {
      if (v === 'logout') this.logout();
    },
    /**
     * 登出
     */
    async logout() {
      const { status } = await $Api.user.logout();
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.clearUserInfo();
        this.$sessionStorage.setItem(config.USER_TOKEN_KEY, '');
        this.$cookies.remove(config.COOKIE_TOKEN);
        this.$router.push('/login');
      }
    }
  
  },
}
</script>

<style lang="scss">
.header-right-group.chat {
  display: flex;
  align-items: center;
  .el-button.is-circle{
    // height: 28px;
    // text-align: center;
    // line-height: 0;
  }
  .hrg-item {
    margin-left: 24px;
  }
  .mgL4{
    margin-right: 4px;
  }
  .mgL8{
    margin-right: 8px;
  }
  .mgL16{
    margin-right: 16px;
  }
  
  .switch-lang {
    width: 12px;
    font-weight: 100;
    align: center;
    // margin-right: 4px;
    display: inline-block;
  }
.dropdown-userCenter-popper.el-dropdown-menu {
  border-color: transparent;

  .el-dropdown-menu__item{
    color: #ccc;
  }
}
  .el-button {
    background: $--color-primary;
    color: #FFFFFF;
    border-color: transparent;
  }
}
</style>
