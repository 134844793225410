export default {
  'dc:请输入': '请输入',
  'dc:请输入名称': '请输入名称',
  'dc:请选择': '请选择',
  'dc:GPT请输入': '请输入...',
  'dc:新增': '新增',
  'dc:退出': '退出',
  'dc:刷新': '刷新',
  'dc:删除': '删除',
  'dc:移除': '移除',
  'dc:计算': '计算',
  'dc:详情': '详情',
  'dc:下载': '下载',
  'dc:上传': '上传',
  'dc:确认': '确认',
  'dc:取消': '取消',
  'dc:保存成功': '保存成功',
  'dc:上传成功': '上传成功',
  'dc:成功': '成功',
  'dc:登录': '登录',
  'dc:保存': '保存',
  'dc:加载中': '加载中',
  'dc:运行中': '运行中',
  'dc:返回': '返回',
  'dc:删除成功': '删除成功',
  'dc:查看': '查看',
  'dc:操作': '操作',
  'dc:编辑': '编辑',
  'dc:发送': '发送',
  'dc:是': '是',
  'dc:否': '否',
  'dc:发送邮件': '发送邮件',
  'dc:邮件通知': '邮件通知',
  'dc:同意': '同意',
  'dc:不同意': '不同意',
  'dc:关闭': '关闭',
  'dc:生成': '生成',
  'dc:重置': '重置',
  'dc:提交': '提交',
  'dc:未分配': '未分配',
  'dc:已分配': '已分配',
  'dc:转变': '切换',
  'dc:数据为空': '数据为空',
  'dc:复制': '复制',
  'dc:欢迎': '欢迎! ',
  // Header
  'dc:用户反馈': '用户反馈',
  'dc:类别': '类别',
  'dc:内容': '内容',
  // 修改密码
  'dc:修改密码': '修改密码',
  'dc:用户名字': '用户名字',
  'dc:新密码': '新密码',
  'dc:确认新密码': '确认新密码',
  'dc:请输入拒绝理由': '请输入拒绝理由',
  'dc:提醒': '提醒',
  // 用户维护
  'dc:名字': '名字',
  'dc:密码': '密码',
  'dc:禁止': '禁用',
  'dc:恢复': '恢复',
  'dc:拒绝 ': '拒绝',
  'dc:申请': '申请',
  'dc:管理': '管理',

  'dc:选择版本': '选择版本',

  'dc:品牌名称': '品牌名称',
  'dc:项目名称': '项目名称',
  'dc:产品名称': '产品名称',
  'dc:用户组管理': '用户组管理',
  'dc:用户组信息': '用户组信息',
  'dc:请输入账号名称': '请输入账号名称',
  'dc:请输入产品名称': '请输入产品名称',
  'dcH:用户组管理': '用户组管理',
  'dc:密码已重置，请重新登录': '密码已重置，请重新登录.',

  'dc:您确定要去用户组吗?': '确定要去用户组吗?',
  'dc:您确定要添加该用户吗?': '确定要添加该用户吗?',
  'dc:您确定要拒绝此用户吗?': '确定要拒绝此用户吗?',
  'dc:您确定要删除此用户吗?': '确定要删除此用户吗?',
  'dc:您确认要邀请此用户吗?': '确认要邀请此用户吗?',
  'dc:请选择或者输入': '请选择或者输入',
  'dc:没有结果。请邀请您要找的人注册。': '没有结果。请邀请您要找的人注册.',
  'dc:请输入账号名称或邮箱': '请输入账号名称或邮箱',

  'dc:您确认要禁止该用户吗?': '确认要禁止该用户吗?',
  'dc:您确认要恢复该用户吗?': '确认要恢复该用户吗?',
  'dc:您确认要拒绝该用户吗?': '确认要拒绝该用户吗?',
  'dc:您确认要删除该用户吗?': '确认要删除该用户吗?',
  'dc:您是否确认添加此帐户?': '是否确认添加此帐户?',
  'dc:您是否确认添加此Lion ID?': '是否确认添加此Lion ID?',

  'dc:您确认要禁止该组吗?': '确认要禁止该组吗?',
  'dc:您确认要恢复该组吗?': '确认要恢复该组吗?',
  'dc:您确认要拒绝该组吗?': '确认要拒绝该组吗?',
  'dc:您确认要删除该组吗?': '确认要删除该组吗?',
  'dc:您确认退出该组吗?': '确认退出该组吗?',
  'dc:您是否确认申请改组吗?': '是否确认申请改组吗?',
  'dc:您确定要进入用户管理吗?': '确定要进入用户管理吗?',

  // 账户角色分配
  'dc:新增账户': '新增账户',
  'dc:添加LION ID': '添加LION ID',
  'dc:代理商': '代理',
  'dc:权限申请列表': '权限申请列表',
  'dc:更新时间': '更新时间',
  'dc:用户手册': '用户手册',
  'dc:你确定选择这个媒介吗?': '确定选择这个媒介吗?',
  'dc:请输入关键词': '请输入关键词',
  'dc:账户角色分配': '账户角色分配',
  'dc:产品码': '产品码',
  'dc:产品地址': '产品地址',
  'dc:介绍': '介绍',
  'dc:问卷调查': '问卷调查',
  'dc:马上填写': '马上填写',
  'dc:稍后填写': '稍后填写',
  'dc:您确认注册这个产品吗?': '确认注册这个产品吗?',
  'dc:您确认选择该产品吗?': '确认选择该产品吗?',
  'dc:您确认禁止该产品吗?': '确认禁止该产品吗?',
  'dc:您确认恢复该产品吗?': '确认恢复该产品吗?',
  'dc:您确认删除此访问权限吗?': '确认删除此访问权限吗?',
  'dc:您确认添加此访问权限吗?': '确认添加此访问权限吗?',
  'dc:您确认编辑此访问权限吗?': '确认编辑此访问权限吗?',
  'dc:您是否确认要申请该用户组？您的申请将由该组管理员审批，审批结果将会邮件告知您。':
    '您是否确认要申请该用户组？您的申请将由该组管理员审批，审批结果将会邮件告知您。',
  'dc:能否请您花几分钟宝贵的时间,单击以下链接给SSO系统一些反馈？':
    '能否请您花几分钟宝贵的时间,单击以下链接给SSO系统一些反馈？',

  'dc:请您花几分钟宝贵的时间通过单击下面的链接提供一些有关':
    '请您花几分钟宝贵的时间通过单击下面的链接提供一些有关 ',
  'dc:的反馈？': '的反馈?',
  // 角色
  'dc:角色分配': '角色分配',
  'dc:角色名称': '角色名称',
  'dc:帐户类型': '帐户类型',
  'dc:代理商/品牌': '代理/品牌',
  'dc:成员名字': '成员名字',
  'dc:您确认分配这个角色吗?': '确认分配这个角色吗?',
  'dc:您确认删除这个角色吗?': '确认删除这个角色吗?',

  'dc:提交申请': '提交申请',
  'dc:人工审核': '人工审核',
  'dc:邮件确认': '邮件确认',
  'dc:申请完成': '申请完成',
  'dc:待处理': '待处理',
  'dc:审批通过': '审批通过',
  'dc:拒绝': '拒绝',
  'dc:首页': '首页',
  'dc:用户中心': '用户中心',
  // 权限
  'dc:产品名称': '产品名称',
  'dc:新增产品': '新增产品',
  'dc:产品列表': '产品列表',
  'dc:选择产品': '选择产品',
  'dc:权限名称': '权限名称',
  'dc:新增权限': '新增权限',
  'dc:权限': '权限',
  'dc:权限列表': '权限列表',
  'dc:客户': '客户',
  'dc:模块': '模块',
  'dc:模块可视化': '模块可视化',
  'dc:模块名称': '模块名称',
  'dc:权限code': '权限code',
  'dc:添加权限': '添加权限',
  'dc:规则命名': '规则命名',
  'dc:描述': '描述',
  'dc:代理商': '代理',
  'dc:产品注册': '产品注册',
  'dc:添加角色': '添加角色',
  'dc:编辑角色': '编辑角色',
  'dc:角色列表': '角色列表',
  'dc:编辑权限': '编辑权限',
  'dc:权限分配': '权限分配',
  'dc:权限详情': '权限详情',
  'dc:状态': '状态',
  'dc:分配': '分配',
  'dc:未选择': '未选择',
  'dc:已选择': '已选择',
  'dc:跳转至权限列表': '跳转至权限列表',
  'dc:部门': '部门',
  'dc:指定': '指定',
  // 账号
  'dc:账号名称': '账号名称',
  'dc:账号管理': '账号管理',
  'dc:账号维护': '账号维护',
  'dc:账号关键词': '账号关键词',
  'dc:账号状态': '账号状态',
  'dc:账号列表': '账号列表',
  'dc:邀请人的邮箱': '邀请人的邮箱',
  'dc:新增账号': '新增账号',
  'dc:请选择产品': '请选择产品',
  'dc:集团信息': '集团信息',

  'dc:新增模块': '新增模块',
  'dc:编辑模块': '编辑模块',
  'dc:删除模块': '删除模块',
  'dc:您是否确认添加此模块?': '是否确认添加此模块?',
  'dc:模块名称_1st': '模块名称1',
  'dc:模块名称_2st': '模块名称2',
  // 组列表
  'dc:分配到组': '用户组权限分配',
  'dc:用户组': '用户组',
  'dc:新增用户组': '新增用户组',
  'dc:编辑用户组': '编辑用户组',
  'dc:添加用户组': '添加用户组',
  'dc:用户组名称': '用户组名称',
  'dc:用户组列表': '用户组列表',
  'dc:选择管理员': '选择管理员',
  'dc:选择成员': '选择成员',
  'dc:请输入用户组名称': '请输入用户组名称',
  'dc:请输入并选择': '请输入并选择',
  'dc:是否确认将这些访问权限分配给此组?': '是否确认将这些访问权限分配给此组?',
  'dc:你确认当前分配吗？': '确认保存当前分配结果吗？',

  'dc:您确认邀请吗?': '您确认邀请吗?',
  'dc:请输入想要邀请的同事邮箱': '请输入想要邀请的同事邮箱',
  'dc:注意每次最多同时邀请5位同事': '注意每次最多同时邀请5位同事',
  'dc:没有结果？请邀请您要找的人注册': '没有结果？请邀请您要找的人注册',
  'dc:请输入用户名称或邮箱': '请输入用户名称或邮箱',

  'dc:您确认将这些访问权限分配给该组吗？': '确认将这些访问权限分配给该组吗？',
  'dc:您确认分配这个组吗？': '确认分配这个组吗？',
  'dc:您是否确认当前操作访问权限已分配给该组?': '是否确认当前操作访问权限已分配给该组?',

  'dc:确认将当前操作访问权限分配给该组吗': '确认将当前操作访问权限分配给该组吗?',
  'dc:确认将当前数据访问权限分配给该组吗': '确认将当前数据访问权限分配给该组吗?',

  'dc:确认切换到数据访问页面并保存当前更改': '确认切换到数据访问页面并保存当前更改?',
  'dc:确认切换到操作访问页面并保存当前更改': '确认切换到操作访问页面并保存当前更改?',

  'dc:确认切换到客户端并保存当前更改吗': '确认切换到客户端并保存当前更改吗?',
  'dc:确认切换到行业并保存当前更改吗': '确认切换到行业并保存当前更改吗?',

  'dc:角色管理': '角色管理',
  'dc:管理员': '管理员',
  'dc:修改代理': '修改代理',
  // 用户组
  'dc:新增用户': '新增用户',
  'dc:用户名称': '用户名称',
  'dc:创建时间': '创建时间',
  'dc:用户组人数': '用户组人数',
  'dc:组管理员列表': '组管理员列表',
  'dc:组管理者': '组管理者',
  'dc:组名/组管理者': '组名/组管理者',
  'dc:组管理人员列表': '组管理人员列表',
  'dc:用户列表': '用户列表',
  'dc:操作权限': '操作权限',
  'dc:数据权限': '数据权限',
  'dc:编辑账号': '编辑账号',
  'dc:用户组管理员': '用户组管理员',
  'dc:重设密码': '重设密码',
  'dc:翻译': '翻译',
  'dc:公告列表': '公告列表',
  'dc:新增发布': '新增发布',
  'dc:版本': '版本',
  'dc:发布时间': '发布时间',
  'dc:发布者': '发布者',
  'dc:新增公告信息': '新增公告',
  'dc:发布版本': '发布版本',
  'dc:发布内容': '发布内容',
  'dc:选择日期': '选择日期',

  // 用户管理
  'dc:请输入用户名称': '请输入用户名称',
  'dc:结果如下。请选择您需要的会员并确认': '结果如下。请选择您需要的会员并确认',
  'dc:没有结果。请邀请您要找的人注册。': '没有结果。请邀请您要找的人注册。',
  'dc:邀请': '邀请',
  'dc:输入邮箱地址': '输入邮箱地址',
  'dc:请输入正确的邮箱': '请输入正确的邮箱',
  'dc:您确认要添加此组吗?': '确认要添加此组吗?',
  'dc:您确认要编辑此组吗?': '确认要编辑此组吗?',
  'dc:请输入用户组名称/用户组管理员': '请输入用户组名称/用户组管理员',
  'dc:您确定要修改此组成员吗？': '确定要修改此组成员吗？',

  'dc:禁用': '禁用',
  'dc:恢复': '恢复',
  'dc:驳回': '驳回',
  'dc:品牌': '品牌',
  'dc:邮箱': '邮箱',
  'dc:添加品牌': '添加品牌',
  'dc:查找': '查找',
  'dc:修改品牌': '修改品牌',
  'dc:审批驳回': '审批驳回',
  'dc:权限管理': '权限管理',
  'dc:品牌定义': '品牌定义',
  'dc:权限定义': '权限定义',
  'dc:角色定义': '角色定义',
  'dc:编码': '编码',
  'dc:URL': 'URL',
  'dc:产品': '产品',
  'dc:您确定要删除吗?': '确定要删除吗?',
  'dc:重载': '重载',
  'dc:时间范围': '时间范围',
  'dc:开始日期': '开始日期',
  'dc:结束日期': '结束日期',
  'dc:重新加载': '重新加载',
  'dc:修改密码': '修改密码',
  'dc:新密码与确认密码不匹配': '新密码与确认密码不匹配.',
  'dc:密码不少于8位，不大于20位': '密码不少于8位，不大于20位.',
  'dc:仅支持数字、大小写字母': '仅支持数字、大小写字母.',
  'dc:修改密码成功，请重新登录': '修改密码成功，请重新登录.',
  'dc:请输入密码': '请输入密码.',
  'dc:您确定要修改密码吗？': '确定要修改密码吗？',

  'dc:模块列表': '模块列表',
  'dc:您是否确认删除此模块?': '是否确认删除此模块?',
  'dc:您是否确认编辑此模块?': '是否确认编辑此模块?',
  'dc:请按规则输入': '请按规则输入',
  'dc:历史记录': '历史记录',
  'dc:所有记录': '所有记录',
  'dc:审批人': '审批人',
  'dc:纬度': '纬度',
  'dc:行业': '行业',
  'dc:用于访问应用程序和使用顾问。请联系': '用于访问应用程序和使用顾问。请联系',
  'dc:寻求帮助': '寻求帮助。',
  'dc:之前需要通过电子邮件批准您的直线经理的访问权限':
    '之前需要通过电子邮件批准您的直线经理的访问权限.',
  'dc:去首页': '去首页',
  'dc:请添加数据': '请添加数据',
  // 反馈中心
  'dc:反馈中心': '反馈中心',
  'dc:调查列表': '调研问卷列表',
  'dc:反馈列表': '反馈列表',
  'dc:调查名称': '调研问卷名称',
  'dc:提交时间': '提交时间',
  'dc:新增调查信息': '新增调研问卷',
  'dc:反馈类别': '反馈类别',
  'dc:发布公告': '公告列表',
  'dc:单击下面的链接以创建新调查': '单击下面的链接创建新调研问卷',
  'dc:或者您可以去其他平台创建调查': '或者您可以去其他平台创建',
  'dc:反馈详情': '反馈详情',
  'dc:解决': '已解决',
  'dc:未解决': '未解决',
  'dc:编辑公告信息': '编辑公告',
  'dc:跳转至公告列表': '跳转至公告列表',
  'dc:上一页': '上一页',
  'dc:下一页': '下一页',
  'dc:可见': '可见',
  'dc:不可见': '不可见',
  'dc:确定删除?': '确定删除?',
  'dc:编辑调查信息': '编辑调研问卷',
  'dc:公告信息详情': '查看公告',
  'dc:开': '打开',
  'dc:关': '关闭',
  'dc:确认添加': '确认添加?',
  'dc:确认修改': '确认修改?',
  'dc:确认提交': '确认提交?',
  'dc:公告': '公告',
  'dc:仅支持编号。大写和小写字母': '仅支持编号。大写和小写字母',
  'dc:仅支持100个字符': '仅支持100个字符',

  // 改版首页数据
  'dc:正确使用': 'MAKE IT RIGHT',
  'dc:使其魔幻': 'MAKE IT MAGIC',
  'dc:使其工作': 'MAKE IT WORK',
  'dc:常用的': '我的常用',
  'dc:团队APP': '团队APP',
  'dc:知识技能': '知识资源库',
  'dc:资源': '资源',
  'dc:运动': '运动',
  // 产品详情
  'dc:什么是IT?': 'WHAT IS IT?',
  'dc:何时何地使用?': 'PROBLEMS WE ARE SOLVING?',
  'dc:世界卫生组织应该使用它?': 'WHY USE IT?',
  'dc:如何访问?': 'HOW TO GET ACCESS?',
  'dc:演示': 'DEMO',
  'dc:文件夹': 'FILES',
  'dc:转到主页': '进入首页',
  'dc:获取访问权限': '申请权限',

  'dc:第三方工具': '第三方工具',
  'dc:简介': '简介',
  'dc:什么是IT': '什么是IT?',
  'dc:为什么要使用它': '为什么要使用它?',
  'dc:申请权限的方式': '申请权限的方式',
  'dc:附件': '附件',
  'dc:produc描述': '描述',
  'dc:用途': '用途',
  'dc:特点和优势': '特点和优势',
  'dc:智能机器人': 'ChatGPT',
  'dc:确认删除': '确认删除?',
  'dc:咨询费共花费': '总花费',
  'dc:新增会话': '新增会话',
  'dc:你好今天我能为您提供什么帮助？': '你好今天我能为您提供什么帮助？',
  'dc:请认真阅读使用规范': '请认真阅读使用规范',
  'dc:责任声明1':
    '请注意：本工具为使用ChatGPT (gpt-3.5-turbo)语言模型的测试版本。系统可能会偶尔生成不正确或误导性信息，以及产生冒犯或有偏见的内容。它不旨在提供业务建议，因此请在使用之前验证ChatGPT的输出。',
  'dc:责任声明2':
    '请遵守关于数据安全和隐私保护义务的相关政策和要求。所有聊天记录都将被记录和分析，以供未来改进和遵从性监控。',
  'dc:责任声明3':
    '请不要透露任何集团或我们的客户有关的业务数据或机密信息。对任何违反集团或客户协议的机密信息共享者，集团将采取严厉与及时的纪律处理。',
  'dc:责任声明4':
    '请避免透露与公司员工或客户有关的个人信息，并注意防止侵犯数据隐私。不建议上传带有手机号或设备ID信息的个人信息。',
  'dc:责任声明5': '在使用任何生成的内容之前，请确保其符合广告法并不侵犯任何知识产权。',
  'dc:责任声明6':
    '由于语言模型的限制，对话历史会被限制为2000个单词之内传递给AI引擎，这同时导致成本指数上升。因此，如果有新的上下文情况适用，请开始一个新的会话。',
  'dc:责任声明7':
    '所有聊天记录都将在集团层面被记录和跟踪，以供未来改进和合规性监控。如果违反以上任何协议，集团将采取严厉措施。',
  'dc:换行': '换行',
  'dc:剩余流量': '剩余流量',
  'dc:你的流量用完了': '你的流量用完了.',
  'dc:您的对话已超出限制': '您的对话已超出限制.',
  'dc:EDGEAI': 'EDGE AI',
  'dc:帮助中心': '帮助中心',
  'dc:IRIS映射列表': 'IRIS映射列表',
  'dc:请选择审核状态': '请选择审核状态',
  'dc:级别2': '级别2',
  'dc:级别3': '级别3',
  'dc:批准人': '批准人',
  'dc:审核状态': '审核状态',
  'dc:正常': '正常',
  'dc:例如:MurielLiu': '例如:MurielLiu',
  'dc:选择模块': '选择模块',
  'dc:已发送重置邮件给到管理员，请等待审批邮件通知！':
    'A reset email has been sent to the administrator, please wait for the approval email notification!',
  'dc:请输入Code关键词': '请输入Code关键词',
}
