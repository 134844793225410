<template>
  <div class="header-right-group">
    <el-tooltip effect="dark" :content="$t('dc:公告列表')" placement="top">
      <el-button type="primary" @click="showHistoryLanch" circle>
        <i class="poc-size14 iconfont icon-oneui-gonggao user"></i>
      </el-button>
    </el-tooltip>
    <el-tooltip effect="dark" :content="$t('dc:首页')" placement="top">
      <el-button type="primary" circle @click="goSSOHome"><i class="el-icon-s-home"></i></el-button>
    </el-tooltip>
    <el-tooltip effect="dark" :content="$t('dc:用户中心')" placement="top">
      <el-dropdown trigger="click" class="hrg-item" @command="onCommand">
        <el-button type="primary" circle><i class="el-icon-user-solid"></i></el-button>
        <el-dropdown-menu slot="dropdown" class="dropdown-userCenter-popper" style="color: #ccc">
          <el-dropdown-item command="info" disabled>{{ userName }}</el-dropdown-item>
          <el-dropdown-item command="logout" icon="poc-size12 iconfont icon-oneui-logout">{{
            $t('dc:退出')
          }}</el-dropdown-item>
          <el-dropdown-item
            v-if="showMsg"
            command="resetPassword"
            icon="poc-size14 el-icon-setting"
            >{{ $t('dc:修改密码') }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </el-tooltip>

    <el-button type="primary" @click="switchLang" circle class="hrg-item">
      <span class="switch-lang">{{ this.$i18n.locale === 'zh-cn' ? 'En' : '中' }}</span>
    </el-button>

    <launchAnnouncementDialog
      v-if="showLaunchStatus"
      :visible.sync="showLaunchStatus"
      :launchDataList="launchDataList"
    ></launchAnnouncementDialog>
    <historyAnnouncementDialog
      v-if="showLaunchHistoryStatus"
      :visible.sync="showLaunchHistoryStatus"
      :historyDataList="historyDataList"
    ></historyAnnouncementDialog>
  </div>
</template>

<script>
import config from '@/api/config'

import launchAnnouncementDialog from '@/views/dialog/launchAnnouncementDialog'
import historyAnnouncementDialog from '@/views/dialog/historyAnnouncementDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HeaderRightGroup',
  props: {
    showMsg: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name === 'user.Password') this.urlName = 'password'
        if (to.name === 'user.Apply') this.urlName = 'apply'
      },
    },
  },
  components: {
    launchAnnouncementDialog,
    historyAnnouncementDialog,
  },
  data() {
    return {
      showUser: true,
      showLaunchStatus: false,
      showNextPage: false,
      showLaunchHistoryStatus: false,
      launchDataList: {},
      historyDataList: [],
      nexPageSize: 1,
      urlName: 'home',
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    userName() {
      if (this.$route.name === 'user.Apply') {
        this.showUser = false
        return ''
      } else return this.userInfo.fullName || ''
    },
  },
  mounted() {
    if (this.showMsg) return this.initEvent()
  },
  methods: {
    ...mapActions(['clearUserInfo']),
    initEvent() {
      this.getAnnouncementDialog({ appName: this.$PROJECT_CODE })
    },
    // 获取弹窗信息
    async getAnnouncementDialog(params) {
      let { status, data } = await $Api.announcement.popupAnnouncement(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data == null) return

        this.launchDataList = data
        this.showNextPage = false
        this.showLaunchStatus = true
      }
    },
    /**
     * 到SSO的home页
     */
    goSSOHome() {
      if (this.showMsg) this.$router.push({ name: 'home' })
      else {
        if (this.urlName === 'apply') this.$router.push({ name: 'login' })
        else this.$router.go(-1)
      }
    },
    /**
     * 语言切换：zh-cn | en-us
     */
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === 'zh-cn' ? 'en-us' : 'zh-cn'
      this.upDataLang()
    },
    /**
     * 存储用户语言
     */
    async upDataLang() {
      let { status } = await $Api.user.setLang({
        appName: this.$PROJECT_CODE,
        lang: this.$i18n.locale,
      })
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$localStorage.setItem('lang', this.$i18n.locale)
      }
    },
    showHistoryLanch() {
      this.showLaunchHistoryStatus = true
      // this.getAnnouncementHistory(this.historyParams)
    },

    /**
     * dropdown项点击
     */
    onCommand(v) {
      if (v === 'logout') this.logout()
      if (v === 'resetPassword') this.$router.push({ name: 'user.Password' })
    },
    /**
     * 登出
     */
    async logout() {
      const { status } = await $Api.user.logout()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.clearUserInfo()
        this.$sessionStorage.setItem(config.USER_TOKEN_KEY, '')
        this.$cookies.remove(config.COOKIE_TOKEN)
        this.$router.push('/login')
      }
    },
  },
}
</script>

<style lang="scss">
.header-right-group {
  .el-button.is-circle {
    // height: 28px;
    // text-align: center;
    // line-height: 0;
  }
  .hrg-item {
    margin-left: $MP8;
  }

  .switch-lang {
    width: 12px;
    font-weight: 100;
    align: center;
    // margin-right: 4px;
    display: inline-block;
  }

  .dropdown-userCenter-popper.el-dropdown-menu {
    border-color: transparent;
    .el-dropdown-menu__item {
      color: #ccc;
    }
  }
  .el-button {
    background: $--color-primary;
    color: #ffffff;
    border-color: transparent;
  }
}
</style>
