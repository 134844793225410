<template>
  <div class="tmpC">
    <h5>this is C Tmp</h5>
    <input type="text" name="tmpC" v-model="message" v-on="$listeners"/>
    <p>
      来自A的信息: {{$attrs.messageFromA}}
    </p>
  </div>
</template>
<script>
export default {
  name: 'TmpC',
  data() {
    return {
      message: ''
    }
  },
}
</script>

