<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="25%"
      top="10%"
      :close-on-click-modal="false"
      class="user-freedback-dialog portrait"
      :class="otherClass"
      :title="$t('dc:用户反馈')"
      @close="closed"
    >
      <div class="freedbackContent fontB6">
        <el-form
          :model="userFreedBackDataForm"
          :rules="rules"
          ref="userFreedBackDataForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="projectId">
            <template slot="label">
              <div class="colorF displayB">
                {{ $t('dc:产品名称') }}
              </div>
            </template>
            <div>
              <el-select
                v-model="userFreedBackDataForm.projectId"
                filterable
                :placeholder="$t('dc:请选择')"
                popper-class="homeSelect"
                style="width: 100%"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item prop="categoryId">
            <template slot="label">
              <div class="colorF displayB">
                {{ $t('dc:类别') }}
              </div>
            </template>
            <div class="categoryList">
              <ul>
                <li
                  :class="{ active: userFreedBackDataForm.categoryId === item.id }"
                  v-for="item in categoryList"
                  @click="changeCategory(item)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item prop="feedback">
            <template slot="label">
              <div class="colorF displayB">
                {{ $t('dc:内容') }}
              </div>
            </template>
            <div class="categoryContent">
              <el-input
                type="textarea"
                :rows="5"
                :placeholder="$t('dc:请输入')"
                v-model="userFreedBackDataForm.feedback"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button class="btn-black marginR20" type @click="show = false">{{
          $t('dc:取消')
        }}</el-button>
        <el-button class="btn-green" type="primary" @click="submitForm('userFreedBackDataForm')">{{
          $t('dc:提交')
        }}</el-button>
      </div>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :className="classNameOne"
      :title="dielogTitle"
      :content="dielogContent"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>
<script>
import UsuallyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: 'freedbackDialog',
  components: {
    UsuallyDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    linkList: [],
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  data() {
    var projectIdRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input valid format'))
      } else {
        callback()
      }
    }
    var categoryRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input valid format'))
      } else {
        callback()
      }
    }
    var feedbackRoute = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input valid format'))
      } else {
        callback()
      }
    }
    return {
      show: this.visible,
      show4Dialog: false,
      otherClass: 'home',
      classNameOne: 'home',
      dielogTitle: this.$t('dc:提醒'),
      dielogContent: this.$t('dc:确认提交'),
      userFreedBackDataForm: {
        projectId: '',
        appName: '',
        categoryId: '',
        feedback: '',
      },
      categoryList: [
        {
          name: 'Funtional Requirements',
          id: 1,
        },
        {
          name: 'Exterior Interface',
          id: 2,
        },
        {
          name: 'Bug',
          id: 3,
        },
        {
          name: 'System Fluency',
          id: 4,
        },
      ],
      rules: {
        projectId: [{ validator: projectIdRoute, trigger: 'blur' }],
        // appName: [{ validator: productCodeRoute, trigger: ["blur", "change"] }],
        categoryId: [{ validator: categoryRoute, trigger: ['blur', 'change'] }],
        feedback: [{ validator: feedbackRoute, trigger: 'blur' }],
      },
      projectList: [],
    }
  },
  mounted() {
    this.initEvent()
  },

  methods: {
    initEvent() {
      this.getProductList()
    },
    // 获取产品列表
    async getProductList() {
      let { status, data } = await $Api.project.optionList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.projectList = data
      }
    },
    changeCategory(item) {
      this.userFreedBackDataForm.categoryId = item.id
    },
    closed() {
      this.$emit('close')
      this.show = false
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.show4Dialog = true
        } else {
          return false
        }
      })
    },
    confrom() {
      this.show4Dialog = false
      this.submitFormData(this.userFreedBackDataForm)
    },
    async submitFormData(params) {
      let { status } = await $Api.feedback.submitFeedBack(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show = false
        this.$message({
          showClose: true,
          message: this.$t('dc:成功'),
          type: 'success',
        })
      }
    },
  },
}
</script>
<style lang="scss">
.user-freedback-dialog .el-dialog__body div.fontB6 {
  word-break: break-word;
}

.freedback {
  display: block;
  width: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}
.categoryList {
  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
  li {
    color: #fff;
    border: 1px solid #c4c8cf;
    border-radius: 2px;
    font-size: 12px;
    text-align: center;
    flex-basis: 47.5%;
    display: block;
  }
  li.active,
  li:hover {
    cursor: pointer;
    border-color: rgba(74, 227, 13, 1);
    background: rgba(45, 74, 37, 1);
  }
  li:nth-child(1),
  li:nth-child(2) {
    margin-bottom: 16px;
  }
  li:nth-child(2),
  li:nth-child(4) {
    margin-left: 3.2%;
  }
}
.colorF {
  color: #fff;
}
.displayB {
  display: block;
}

.freedbackContent {
}
</style>
