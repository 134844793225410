<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="780px"
      top="8%"
      :show-close="true"
      :close-on-click-modal="false"
      modal-append-to-body
      class="access-detail-dialog home"
      @close="closeAgency"
      id="authority"
    >
      <div slot="title" class="accessBox">
        <i class="el-icon-warning-outline poc-size32"></i>
        <div>
          <span class="msg">
            Please choose your current agency and it will decide your access of product.
            <br />If you have some question,please contact Muriel.Liu
            <a href="javascript:;">{{ email }}</a> for help.
          </span>
        </div>
      </div>
      <div class="imgBox">
        <ul class="imgBox2">
          <li v-for="(item, index) in imgBox1" :key="index" @click="changeAgencyId(item)">
            <img :src="item.imgUrl" alt :class="{ active: agencyId == item.id }" />
          </li>
        </ul>
        <ul class="imgBox2">
          <li v-for="(item, index) in imgBox2" :key="index" @click="changeAgencyId(item)">
            <img :src="item.imgUrl" alt :class="{ active: agencyId == item.id }" />
          </li>
        </ul>
        <ul class="imgBox3">
          <li v-for="(item, index) in imgBox3" :key="index" @click="changeAgencyId(item)">
            <img :src="item.imgUrl" alt :class="{ active: agencyId == item.id }" />
          </li>
        </ul>
        <ul class="imgBox4">
          <li v-for="(item, index) in imgBox4" :key="index" @click="changeAgencyId(item)">
            <img :src="item.imgUrl" alt :class="{ active: agencyId == item.id }" />
          </li>
        </ul>
      </div>
      <div slot="footer">
        <el-button class="btn-black" @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-green" type="primary" @click="saveAgency">{{
          $t('dc:保存')
        }}</el-button>
      </div>
    </el-dialog>
    <usuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="groupTitle"
      :content="groupContent"
      :className="homeClass"
      @close="closeGroup"
      @confrom="confrom"
    ></usuallyDialog>
  </div>
</template>
<script>
import performicsImg from '@/assets/img/performics.png'
import digitasImg from '@/assets/img/digitas.png'
import edageImg from '@/assets/img/edage.png'
import publicissapientImg from '@/assets/img/publicissapient.png'
import saatchiImg from '@/assets/img/saatchi.png'
import sprkImg from '@/assets/img/sprk.png'
import starcomImg from '@/assets/img/starcom.png'
import zenithImg from '@/assets/img/zenith.png'
import prodigiousImg from '@/assets/img/prodigious.png'
import leoburnettImg from '@/assets/img/leoburnett.png'
import luxeImg from '@/assets/img/luxe.png'
import msl from '@/assets/img/msl.png'
import chq from '@/assets/img/chq.png'
import mediaImg from '@/assets/img/media.png'

import bbh from '@/assets/img/bbh.png'
import fallon from '@/assets/img/fallon.png'
import marcel from '@/assets/img/marcel.png'
import pww from '@/assets/img/pww.png'
import cmt from '@/assets/img/cmt.png'
import tpo from '@/assets/img/tpo.png'
import usuallyDialog from '@/views/dialog/usuallyDialog'

import config from '@/api/config'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'agencyList',
  components: {
    usuallyDialog,
  },
  data() {
    return {
      show: this.visible,
      show4Dialog: false,
      agencyId: '',
      email: `<muriel.liu@publicismedia.com>`,
      imgBox1: [
        {
          id: 7,
          imgUrl: digitasImg,
        },
        {
          id: 1,
          imgUrl: edageImg,
        },
        {
          id: 5,
          imgUrl: performicsImg,
        },
        {
          id: 8,
          imgUrl: publicissapientImg,
        },
        {
          id: 6,
          imgUrl: saatchiImg,
        },
      ],
      imgBox2: [
        {
          id: 3,
          imgUrl: sprkImg,
        },
        {
          id: 2,
          imgUrl: starcomImg,
        },
        {
          id: 4,
          imgUrl: zenithImg,
        },
        {
          id: 9,
          imgUrl: prodigiousImg,
        },
        {
          id: 10,
          imgUrl: leoburnettImg,
        },
      ],
      imgBox3: [
        {
          id: 11,
          imgUrl: luxeImg,
        },
        {
          id: 12,
          imgUrl: msl,
        },
        {
          id: 13,
          imgUrl: chq,
        },
        {
          id: 14,
          imgUrl: mediaImg,
        },
        {
          id: 15,
          imgUrl: cmt,
        },
      ],
      imgBox4: [
        {
          id: 16,
          imgUrl: pww,
        },
        {
          id: 17,
          imgUrl: bbh,
        },
        {
          id: 18,
          imgUrl: tpo,
        },
        {
          id: 19,
          imgUrl: marcel,
        },
        {
          id: 20,
          imgUrl: fallon,
        },
      ],
      homeClass: 'home',
      groupTitle: '',
      groupContent: '',
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showAgencyDialog: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  mounted() {
    this.getAgencyId()
  },
  methods: {
    async closeAgency() {
      this.$emit('closeAgency')
    },
    changeAgencyId(item) {
      this.agencyId = item.id
    },
    saveAgency() {
      if (this.agencyId == '') return
      this.groupTitle = this.$t('dc:提醒')
      this.groupContent = this.$t('dc:你确定选择这个媒介吗?')
      this.show4Dialog = true
    },
    // 查询默认的agencyId
    async getAgencyId() {
      let { status, data } = await $Api.user.agencySelect()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.agencyId = data.agencyId
      }
    },
    async quertAssignAgency() {
      const { status, msg } = await $Api.user.assignAgency(this.agencyId)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.$message({
          showClose: true,
          message: msg || this.$t('dc:成功'),
          type: 'success',
        })
      }
      this.show = false
    },
    closeGroup() {},
    confrom() {
      this.quertAssignAgency()
    },
  },
}
</script>

<style lang="scss">
.accessBox {
  display: flex;
  align-items: center;
  color: #fff;
  // padding: 4px;
  i {
    margin-right: 9px;
  }
  span {
    font-size: 12px;
    display: block;
    font-weight: 700;
    a {
      color: #48e30a;
      font-weight: 700;
    }
  }
}
.imgBox {
  // padding-bottom: 16px;
  ul {
    display: flex;
    padding-bottom: 10px;
    // text-align: justify;
  }
  li {
    flex: 1;
    margin: 0 auto;
    padding: 0 8px;
    text-align: center;
    img {
      border-radius: 4px;
      width: 100%;
      height: 90px;
      border: 2px solid transparent;
      // height:100px;
      background: #fff;
    }
    img.active {
      border: 2px solid $--color-primary2;
    }
  }

  .imgBox1 {
    li img {
      width: 97%;
    }
  }
  ul.imgBox4 {
    padding-bottom: 0;
  }
  .imgBox3 li.save {
    text-align: right;
    align-self: flex-end;
    button {
      margin-right: 10px;
    }
  }
}
</style>
