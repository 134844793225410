import user from './user'
import project from './project'
import access from './access'
import role from './role'
import group from './group'
import groupAdmin from './groupAdmin'
import moduleList from './moduleList'
import groupIndustry from './groupIndustry'
import feedback from './feedback'
import announcement from './announcement'
import survey from './survey'
import chatGPT from './chatGPT'
import common from './common'
import irismapping from './irismapping'

export {
  user,
  project,
  access,
  role,
  group,
  groupAdmin,
  moduleList,
  groupIndustry,
  feedback,
  announcement,
  survey,
  chatGPT,
  common,
  irismapping,
}
