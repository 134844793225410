<template>
  <el-dialog
    width="512px"
    top="10%"
    class="chatGPTDialog"
    title="ChatGPT"
    :visible.sync="show"
    :close-on-click-modal="false"
    :modal="false"
    @close="close"
  >
    <div class="chatGPTDialog-main">
      <el-scrollbar ref="chatGPTScrollbar" style="height: 45vh" class="chatGPTDialog-main-scroller">
        <ul>
          <li v-for="(item, index) in msgData.messageList" :key="index">
            <div v-if="item.role == 'assistant'" class="chatGPTDialog-main-left">
              <div class="chatGPTDialog-main-left-img">
                <img :src="chatImg" alt="" srcset="" />
              </div>
              <div class="chatGPTDialog-main-left-msg">
                <span>ChatGPT</span>
                <p>
                  <i class="arrow-left"></i>
                  <i v-if="item.content === '...'" class="el-icon-loading"></i>
                  <template v-else>
                    {{ item.content }}
                  </template>
                </p>
              </div>
            </div>
            <div v-if="item.role == 'user'" class="chatGPTDialog-main-right">
              <div class="chatGPTDialog-main-right-img">
                <!-- <img :src="chatImg" alt="" srcset=""> -->
                <span>ME</span>
              </div>
              <div class="chatGPTDialog-main-right-msg">
                <span>{{ userInfo.fullName }}</span>
                <p>
                  <i class="arrow-right"></i>
                  {{ item.content }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </el-scrollbar>
    </div>
    <div slot="footer" class="footer-solt">
      <el-input
        type="text"
        class="chatGPTDialog-footer"
        :rows="2"
        v-model="inputValue"
        :placeholder="$t('dc:GPT请输入')"
        @keyup.enter.native="enterMsg"
      >
      </el-input>
      <img class="enterKey" @click="enterMsg" :src="enterkey" alt="" />
    </div>
  </el-dialog>
</template>
<script>
import chatImg from '@/assets/img/chatImg.png'
import enterkey from '@/assets/img/enterkey.png'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'chatGPT',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    msgData: {
      deep: true,
      immediate: false,
      handler(to) {
        this.$nextTick(() => {
          this.$refs.chatGPTScrollbar.wrap.scrollTop = this.$refs.chatGPTScrollbar.wrap.scrollHeight
        })
      },
    },
  },
  data() {
    return {
      chatImg,
      enterkey,
      show: this.visible,
      loading: false,
      inputValue: '',
      msgData: {
        chatId: null,
        messageList: [
          {
            role: 'assistant',
            content: 'Hi',
          },
        ],
      },
      chatGPTParams: {
        chatId: null,
        messageList: [],
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {},
  // updated(){
  //   let scrollbarEl = this.$refs.chatGPTScrollbar.wrap
  //   scrollbarEl.onscroll = () => {
  //     if(scrollbarEl.scrollTop == 0) {

  //     }
  //   }
  // },
  methods: {
    enterMsg() {
      if (this.inputValue == '' || this.loading) return
      let chatParams = {
        role: 'user',
        content: this.inputValue,
      }
      this.chatGPTParams.messageList.push(chatParams)
      this.msgData.messageList.push(chatParams)
      this.inputValue = ''
      this.askChat(this.chatGPTParams)
    },
    async askChat(params) {
      this.loading = true
      let cmsg = {
        role: 'assistant',
        content: '...',
      }
      this.msgData.messageList.push(cmsg)
      let { status, data } = await $Api.chatGPT.getChatGPTMsg(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.chatGPTParams.chatId = data.chatId
        // this.msgData.messageList.push(data.messageList[0])
        cmsg.content = data.messageList[0].content
        this.chatGPTParams.messageList.push(data.messageList[0])
        this.loading = false
      } else this.loading = false
    },
    close() {
      this.msgData = {
        chatId: '',
        messageList: [
          {
            role: 'assistant',
            content: 'Hi',
          },
        ],
      }
      this.loading = false
      this.chatGPTParams = {
        chatId: '',
        messageList: [],
      }
    },
  },
}
</script>
<style lang="scss">
$border-color: #4e4e4e;
.chatGPTDialog {
  i,
  p {
    margin: 0;
    padding: 0;
  }
  &-main-scroller ul {
    padding: 16px;
    li {
      overflow: hidden;
      margin-bottom: 10px;
    }
  }
  &-main .el-loading-mask {
    background: rgba(40, 42, 43, 0.95);
    .el-loading-spinner .path {
      stroke: #fff;
    }
  }
  &-main-left {
    display: flex;
    flex-direction: reverse;
  }
  &-main-right {
    display: flex;
    flex-direction: row-reverse;
  }
  &-main-left-img,
  &-main-right-img {
    background: #000000;
    height: 36px;
    padding: 0 2px;
    border: 1px solid #96979a;
    text-align: center;
    display: flex;
    align-items: center;
    img,
    span {
      display: block;
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  &-main-left-img {
    margin-right: 10px;
  }
  &-main-right-img {
    margin-left: 10px;
  }
  &-main-left-msg,
  &-main-right-msg {
    // overflow: hidden;
    span {
      color: #fff;
      display: block;
      height: 12px;
      line-height: 1;
      margin-bottom: 6px;
    }
    p {
      padding: 12px 8px;
      background: #dedfdf;
      border-radius: 6px;
      color: #000000;
      font-weight: 500;
      position: relative;
    }
  }
  &-main-right-msg p {
    float: right;
  }
  &-main-left-msg p {
    float: left;
  }
  &-main-right-msg p {
    background-color: #4ae30d;
  }

  &-main-right-msg span {
    text-align: right;
  }
  &-footer {
    .el-input__inner {
      padding: 0;
      height: 32px;
      width: 100%;
      border: none;
      resize: none;
      color: #fff !important;
      background: transparent;
      caret-color: #4ae30d;
    }
    // .el-textarea__inner::-webkit-scrollbar{
    //     width: 2px ;
    //     height:2px ;
    // }
    // .el-textarea__inner::-webkit-scrollbar-thumb {
    //     border-radius: 3px ;
    //       -moz-border-radius: 3px ;
    //       -webkit-border-radius: 3px ;
    //       background-color: #4AE30D ;
    // }
    // .el-textarea__inner::-webkit-scrollbar-track {
    //     background-color: transparent ;

    //   }
  }
  .footer-solt {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .enterKey {
    display: block;
    width: 16px;
    // position: absolute;
    // bottom:16px ;
    // right: 16px;
    cursor: pointer;
  }
  .arrow-left {
    position: absolute;
    top: 14px;
    left: -12px;
    border: 6px solid;
    border-color: transparent #dedfdf transparent transparent;
    height: 0px;
  }
  .arrow-right {
    position: absolute;
    top: 14px;
    right: -12px;
    border: 6px solid;
    border-color: transparent transparent transparent #4ae30d;
    height: 0px;
  }
  .el-dialog {
    background-color: #323435;
    border: 1px solid $border-color;
  }
  .el-dialog__header {
    .el-dialog__title,
    .el-icon-close {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 20px !important;
    }
    .el-dialog__headerbtn:hover .el-dialog__close {
      color: #fff;
    }
    border-bottom: 1px solid $border-color;
  }
  .el-dialog .el-dialog__body {
    padding: 0;
  }
  .el-dialog__footer {
    border-top: 1px solid $border-color;
  }
}
</style>
