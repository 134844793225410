// import PublicisCommon from 'publicis-common';
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

const { Vue, Vuex } = $PCommon
const state = {
  // 用户信息
  userInfo: '',
  // 管理者信息
  managerStatus: false,
  // 项目列表数据
  appLinkList: [],
  // 菜单收起/展开
  isCollapse: false,
  // 系统语言
  lang: 'en-us',
  accessGroup: [],
  //品牌列表
  agencyList: [],
  //代理列表
  clientList: [],
}

Vue.use(Vuex)
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
})
