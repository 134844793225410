const { VUE_APP_BASEURL } = process.env
export default {
  /**
   * 权限别表
   */
  getAccessList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/list`, { params: data })
  },
  /**
   * 权限下拉列表
   */
  getModuleList(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/module/option-list`, {
      params: { projectId: id },
    })
  },
  /**
   * 增加权限
   */
  addAccess(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/access/add`, params)
  },
  /**
   * 删除权限
   */
  delAccess(accessId) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/access/del`, { accessId: accessId })
  },
  /**
   * 权限详情
   */
  accessInfo(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/info`, {
      params: { accessId: id },
    })
  },
  /**
   * 编辑权限
   */
  updataAccess(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/access/upd`, params)
  },
  /**
   * 发送邮件确定审批
   */
  projectExamine(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user/project-examine`, params)
  },
  /**
   * 应用申请状态列表
   */
  getAccessApplicationList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/app/access_application_list`, {
      params: data,
    })
  },
  /**
   * 权限分配用户组列表
   */
  getGroupOption(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/group-option`, {
      params: { accessId: id },
    })
  },
  /**
   * 权限分配 分配用户组
   */
  setAssignGroup(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/access/assign-group`, params)
  },
}
