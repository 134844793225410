
const { $sessionStorage } = $PCommon;

/**
   * 判断当前设备是否为移动端
   */
export const isMobileDevice = () => {
  const ua = navigator.userAgent.toLowerCase();
  const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      ua
  );
  const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1;
  return t1 || t2;
};

/**
 * 判断当前设备是手机
 */
export const isMobilePhone = () => {
  const ua = navigator.userAgent.toLowerCase();
  const t1 = /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(
      ua
  );
  // const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1;
  return t1 ;
};

/**
 * 判断是否是IOS设备
 */
export const isIOS = () => {
  // const ua = navigator.userAgent;
  // return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  var u = window.navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
  return !isAndroid;
};

/**
 * 缓存url上的参数
 */
export const cacheUrlParam = (paramKey, cacheKey) => {
  const reg = eval(`/(\\?|&)${paramKey}=([^&|#]*)/`);
  const matchRes = window.location.search.match(reg);
  const matchValue = matchRes ? matchRes[2] : null;
  if (matchValue) $sessionStorage.setItem(cacheKey, matchValue);
};

/**
 * 判断当前路由是否是绿色通道
 */
export const isGreenChannel = (rootRouter) => {
  const pathname = window.location.pathname;
  const isGC = (router) => {
    // 如果路由匹配，且有绿色标记
    if (router.meta && router.meta.greenChannel && pathname === router.meta.pathname) return true;
    // 继续遍历
    if (router.children && router.children.length) {
      for(let i = 0; i < router.children.length; i++) {
        if (isGC(router.children[i])) return true;
      }
    } else return false;
  };
  return isGC(rootRouter);
};
