// import PublicisCommon from 'publicis-common';
const { $sessionStorage } = $PCommon
/**
 * 为了避免Object/Array引用导致的state中的数据异常变化，在最终赋值时，切断对象引用
 * 原则：state中的数据必须采用commit的方式来进行修改
 */
const mutations = {
  // 获取最新用户信息
  updateUserInfo(state, data) {
    const obj = data ? JSON.parse(JSON.stringify(data)) : null
    state.userInfo = obj
    $sessionStorage.setItem('userInfo', obj)
  },
  /**
   * 菜单收起/展开
   */
  updateIsCollapse(state, isCollapse) {
    state.isCollapse = isCollapse
  },
  updateLang(state, lang) {
    state.lang = lang
  },
  updataManagerStatus(state, data) {
    state.managerStatus = data
  },
  updataAppLinkList(state, data) {
    state.appLinkList = data
  },
  updataLang(state, data) {
    state.lang = data
  },
  // 更新权限组
  updateAccessGroup(state, accessGroup) {
    state.accessGroup = accessGroup
    $sessionStorage.setItem(config.ACCESS_GROUP, accessGroup)
  },
  updataAgencyList(state, data) {
    state.agencyList = data
  },
  updataClientList(state, data) {
    state.clientList = data
  },
}

export default mutations
