<template>
  <div class="tmpB">
    <h4>this is B</h4>
    <input type="text" name="tmpB" v-model="message" v-on="$listeners"/>
    <p>收到A的信息:{{$attrs.messageFromA}}</p>
    <TmpC v-bind="$attrs" v-on="$listeners"/>
  </div>
</template>
<script>
import TmpC from './tmpC'
export default {
  name:"TmpB",
  components: {
    TmpC
  },
  data() {
    return {
      message: ''
    }
  }
}
</script>

