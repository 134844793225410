const Apply = () => import('@/views/accountUser/Apply')
const Password = () => import('@/views/accountUser/Password')

export default {
  routes: [
    {
      path: 'apply',
      name: 'user.Apply',
      component: Apply,
      meta: {
        title: 'Apply',
        greenChannel: true,
        pathname: '/account-user/apply',
      },
    },
    {
      path: 'password',
      name: 'user.Password',
      component: Password,
      meta: {
        title: 'Update Password'
      },
    },
  ],
};
