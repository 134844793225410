<template>
  <el-drawer-transition>
    <slot />
  </el-drawer-transition>
</template>
 
<script>
import { addClass, removeClass } from 'element-ui/lib/utils/dom'
 
export default {
  name: 'EkDrawerTransition',
  components: {
    'el-drawer-transition': {
      functional: true,
      render(createElement, context) {
        const data = {
          on: {
            beforeEnter(el) {
              addClass(el, 'drawer-transition-enter')
              if (!el.dataset) el.dataset = {}
 
              el.dataset.oldPaddingLeft = el.style.paddingLeft
              el.dataset.oldPaddingRight = el.style.paddingRight
 
              el.style.width = '0'
              el.style.paddingLeft = 0
              el.style.paddingRight = 0
              el.style.opacity = 0
            },
            enter(el) {
              el.dataset.oldOverflow = el.style.overflow
              if (el.scrollWidth !== 0) {
                el.style.width = ''
                el.style.paddingLeft = el.dataset.oldPaddingLeft
                el.style.paddingRight = el.dataset.oldPaddingRight
              } else {
                el.style.width = ''
                el.style.paddingLeft = el.dataset.oldPaddingLeft
                el.style.paddingRight = el.dataset.oldPaddingRight
              }
              el.style.overflow = 'hidden'
              el.style.opacity = 1
            },
            afterEnter(el) {
              removeClass(el, 'drawer-transition-enter')
              el.style.width = ''
              el.style.overflow = el.dataset.oldOverflow
              el.style.opacity = 1
            },
            beforeLeave(el) {
              if (!el.dataset) el.dataset = {}
              el.dataset.oldPaddingLeft = el.style.paddingLeft
              el.dataset.oldPaddingRight = el.style.paddingRight
              el.dataset.oldOverflow = el.style.overflow
 
              el.style.width = el.scrollWidth + 'px'
              el.style.overflow = 'hidden'
              el.style.opacity = 1
            },
            leave(el) {
              if (el.scrollWidth !== 0) {
                addClass(el, 'drawer-transition-leave')
                el.style.width = 0
                el.style.paddingLeft = 0
                el.style.paddingRight = 0
                el.style.opacity = 0
              }
            },
            afterLeave(el) {
              removeClass(el, 'drawer-transition-leave')
              el.style.width = ''
              el.style.overflow = el.dataset.oldOverflow
              el.style.paddingLeft = el.dataset.oldPaddingLeft
              el.style.paddingRight = el.dataset.oldPaddingRight
              el.style.opacity = 0
            }
          }
        }
        return createElement('transition', data, context.children)
      }
    }
  }
}
</script>
 
<style lang="scss" scoped>
.drawer-transition-leave {
  transition: 0.5s width ease-in-out, 0.5s padding-left ease-in-out,
    0.5s padding-right ease-in-out, 0.3s opacity ease-in-out;
}
.drawer-transition-enter {
  transition: 0.5s width ease-in-out, 0.3s padding-left ease-in-out,
    0.5s padding-right ease-in-out, 0.6s opacity cubic-bezier(1, 0, 0, 1);
}
</style>