export default {
  data: () => {
    return {
      name: 'testchen',
    }
  },
  computed: {
    dataList() {
      return [
        {
          name: '1',
          id: '2',
        },
      ]
    },
  },
  methods: {
    printT1() {},
    printT2() {},
  },
}
