<template>
  <div v-html="getHtml" ></div>
</template>
<script>
export default {
  props: ['name'],
  data:()=> {
    return{
      rawHtml:``
    }
  },
  computed: {
    getHtml() {
      this.rawHtml = `<h2 style="color:red;">asd</h2>`
      return this.rawHtml
    }
  }
 
}
</script>
