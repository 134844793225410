<template>
  <div class="menu-left">
    <el-scrollbar style="height: calc(100% - 32px)" class="poc-scrollbar-default">
      <el-menu :default-active="menuActive" :collapse="isCollapse" class="menu-list">
        <template v-for="item in menuOptions">
          <el-submenu v-if="item.children && item.children.length" :key="item.id" :index="item.id">
            <template slot="title">
              <i
                class="poc-icon-primary poc-size14"
                :class="item.icon"
                style="margin-right: 4px"
              ></i>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="chlid in item.children"
                :key="chlid.id"
                :index="chlid.id"
                @click="onJump(chlid)"
              >
                {{ chlid.name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item v-else :key="`${item.id}`" :index="item.id" @click="onJump(item)">
            <i class="poc-icon-primary poc-size14" :class="item.icon" style="margin-right: 4px"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
    <div class="menu-left-operation">
      <div class="menu-left-btn" @click="onCollapse">
        <i
          class="poc-size16 menu-left-icon"
          :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 菜单遍历的递归方法
 * @param {Object} menu 菜单对象
 * @param {String} path url地址
 * @param {Function} callback 找到对应菜单的回调，处理对应路由菜单选中
 */
const findResult = (menu, path, callback) => {
  let isFind = menu.url && path.includes(menu.url)
  if (isFind) {
    callback(menu)
  } else {
    if (menu.children && menu.children.length) {
      for (let i = 0; i < menu.children.length; i++) {
        isFind = findResult(menu.children[i], path, callback)
        if (isFind) break
      }
    }
  }
  return isFind
}
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MenuLeft',

  components: {},

  data() {
    return {
      menuActive: '1-1',
    }
  },

  computed: {
    ...mapGetters(['userInfo', 'isCollapse', 'accessList']),
    // 菜单配置
    menuOptions() {
      const menus = [
        {
          id: '1',
          name: this.$t('dc:账号管理'),
          icon: 'iconfont icon-oneui-user',
          accessCode: '',
          children: [
            {
              id: '1-1',
              name: this.$t('dc:账号列表'),
              icon: '',
              url: '/auth-management/account-list',
              accessCode: '',
            },
            {
              id: '1-2',
              name: this.$t('dc:权限申请列表'),
              icon: '',
              url: '/auth-management/access-list',
              accessCode: '',
            },
            {
              id: '1-3',
              name: this.$t('dc:IRIS映射列表'),
              icon: '',
              url: '/auth-management/IRIS-list',
              accessCode: 'account_maintain.AccountManagement.IRISMappingList.view',
            },
          ],
        },
        {
          id: '2',
          name: this.$t('dc:用户组管理'),
          icon: 'iconfont icon-oneui-mdm-client',
          accessCode: '',
          children: [
            {
              id: '2-1',
              name: this.$t('dc:产品列表'),
              icon: '',
              url: '/operation-access/product-list',
              accessCode: '',
            },
            {
              id: '2-2',
              name: this.$t('dc:模块列表'),
              icon: '',
              url: '/operation-access/Module-list',
              accessCode: '',
            },
            {
              id: '2-3',
              name: this.$t('dc:权限列表'),
              icon: '',
              url: '/operation-access/access-list',
              accessCode: '',
            },
            // {
            //   id: '2-3',
            //   name: this.$t('dc:角色列表'),
            //   icon: '',
            //   url: '/operation-access/role-list',
            // },
            {
              id: '2-4',
              name: this.$t('dc:用户组列表'),
              icon: '',
              url: '/operation-access/group-list',
              accessCode: '',
            },
            {
              id: '2-5',
              name: this.$t('dc:公告列表'),
              icon: '',
              url: '/operation-access/announcement-list',
              accessCode: '',
            },
          ],
        },
        {
          id: '3',
          name: this.$t('dc:反馈中心'),
          icon: 'iconfont icon-oneui-feedback-center',
          accessCode: '',
          children: [
            {
              id: '3-1',
              name: this.$t('dc:调查列表'),
              icon: '',
              url: '/feedback-center/survey-list',
              accessCode: '',
            },
            {
              id: '3-2',
              name: this.$t('dc:反馈列表'),
              icon: '',
              url: '/feedback-center/feedback-list',
              accessCode: '',
            },
          ],
        },
      ]
      // 递归清理无权限菜单
      const filterMenus = (menus, accessList) => {
        var newMenus = menus.filter((x) => {
          if (x.accessCode === '' || !!accessList.find((n) => n === x.accessCode)) return x
        })
        newMenus.forEach((x) => x.children && (x.children = filterMenus(x.children, accessList)))
        return newMenus
      }
      return filterMenus(menus, this.accessList)
    },
  },

  watch: {
    // 监听菜单列表，初始化首页
    menuOptions: {
      deep: true,
      immediate: true,
      handler(v) {
        this.initMenuActive(this.$route)
        if (v && v.length && !this.menuActive) {
          const firstMenu = v[0]
          if (firstMenu.children && firstMenu.children.length) {
            this.menuActive = firstMenu.children[0].id
            this.$router.replace(firstMenu.children[0].url)
          } else {
            this.menuActive = firstMenu.id
            this.$router.replace(firstMenu.url)
          }
        }
      },
    },
    $route: {
      immediate: true,
      handler(to) {
        this.initMenuActive(to)
      },
    },
  },

  methods: {
    ...mapActions(['updateIsCollapse']),
    // 显示，隐藏菜单
    onCollapse() {
      this.updateIsCollapse(!this.isCollapse)
    },
    // 跳转
    onJump(menu) {
      if (this.$route.path !== menu.url) {
        this.$router.push(menu.url)
      }
    },
    // 初始化菜单选中
    initMenuActive(to) {
      for (let i = 0; i < this.menuOptions.length; i++) {
        let isFind = findResult(this.menuOptions[i], to.path, (menu) => {
          this.menuActive = menu.id
        })
        if (isFind) break
      }
    },
  },
}
</script>

<style lang="scss">
.menu-left {
  height: 100%;

  .menu-left-operation {
    height: 32px;
    padding: 0 $MP8;
    overflow: hidden;

    .menu-left-btn {
      position: relative;
      height: 100%;
      cursor: pointer;
      border-top: 1px solid $poc-border-color;
    }

    .menu-left-icon {
      position: absolute;
      top: 8px;
      right: calc(50% - 8px);
      font-size: 16px;
      color: $--color-primary;
    }
  }

  .menu-list {
    width: 240px;
    min-height: 500px;
    border-right: 0 !important;

    &.el-menu--collapse {
      width: 58px;
    }
  }
}
</style>
