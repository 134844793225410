import Router from '@/router';
import config from '@/api/config';
const { $http, $sessionStorage, PocResetMessage, $cookies } = $PCommon;
const { VUE_APP_BASEURL } = process.env;
$http.axios.defaults.baseURL = `${VUE_APP_BASEURL}/api/security`;
/**
 * request请求拦截器
 * 1. 给每个请求的header设置token用于鉴权，token来至sessionStorage
 * 2. 为了兼容性，在同域情况下，给cookie也塞token，兼容后端逻辑
 */
 $http.axios.interceptors.request.use(request => {
  const accessToken = $sessionStorage.getItem(config.USER_TOKEN_KEY);
  if (accessToken) {
    request.headers[config.USER_TOKEN_KEY] = accessToken;
  }
  return request;
});

/**
 * 响应拦截器
 * 1. 处理通用提示
 * 2. 处理未登录，跳转sso
 */
$http.axios.interceptors.response.use((response) => {
  // 响应状态为非正常，进行提示
  if (response.data.status > config.RESPONSE_STATUS.SUCCESS) {
    PocResetMessage.warning(response.data.msg);
  }
  // 用户未登录
  if (response.data.status === config.RESPONSE_STATUS.NEED_LOGIN) {
    Router.push({ name: 'login' });
  }
  return response;
});

export default $http;
