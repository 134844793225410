<template>
  <el-card class="appCard mglass" v-loading="loading">
    <div class="appCard-explain" v-if="isCommonData">
      <div class="dpFlex AIC">
        <ul class="other-make">
          <li
            class="appCard-favourite"
            @click="changeTeam(index)"
            :class="{ active: activeIndex === index, JCC: index == 1 }"
            v-for="(item, index) in dataMsg"
            :key="index"
          >
            <div class="favoutriteBox">
              <i class="el-rate__icon el-icon-star-on" v-if="index == 0"></i>
              <span class="title">{{ item.title }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="appCard-explain" v-else>
      <span class="title">{{ dataMsg.title }}</span>
      <el-tooltip placement="top" popper-class="appCard">
        <div slot="content">
          <div class="appCard-mask" :class="{ widthL: widthL }">
            <strong style="font-weight: 600">{{ dataMsg.contentTitle }}</strong>
          </div>
          <span style="display: block; width: 300px">{{ dataMsg.contentMain }}</span>
        </div>
        <i class="el-icon-question appCard-explain-hover"></i>
      </el-tooltip>
    </div>
    <div class="appCard-flex" :class="{ bigImg: className == 'bigImg' }">
      <div>
        <div
          class="appCard-box"
          :class="
            typeId == 1 ? 'FourItem' : typeId == 3 ? 'FiveItem' : typeId == 2 ? 'ThreeItem' : ''
          "
        >
          <div
            v-for="(item, index) in appDataList"
            :key="index"
            class="appCard-item"
            @click="goProjectDetail(item)"
          >
            <div class="appCard-img">
              <img class="" :src="item.iconimgUrl || item.snapshots" />
            </div>
            <span class="appCard-title">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>
<script>
import getAllData from '@/utils/getApplist'
export default {
  name: 'appCardTmp',
  props: {
    type: {
      type: String,
      default: 'home',
    },
    className: {
      type: String,
      default: '',
    },
    appList: {
      type: Array,
      default: [],
    },
    widthL: {
      type: Boolean,
      default: false,
    },
    dataMsg: {},
    isCommonData: {
      type: Boolean,
      default: false,
    },
    typeId: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.iconImgBox = getAllData()
  },
  data() {
    return {
      carouseHeight: '60px',
      appCardIndex: 0,
      activeIndex: 0,
      appDataList: [],
      iconImgBox: [],
      loading: false,
      showIndicatorPosition: 'outside',
    }
  },
  watch: {
    typeId: {
      immediate: false,
      deep: true,
      handler: function () {
        this.getNewList(this.typeId)
      },
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      if (this.isCommonData) {
        if (this.activeIndex == 0) this.getFavorList()
        else this.getTeamList()
      } else this.getNewList(this.typeId)
    },
    changeappCard(v) {
      this.appCardIndex = v
    },
    imageClick(val) {
      if (val == 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    goProjectDetail(item) {
      this.$emit('showProductDrawer', item)
      // if (this.type == 'home') {
      //   this.$router.push({
      //     path: '/productDetail',
      //     query: {
      //       appId: item.id
      //     }
      //   })
      // } else { }
    },
    changeTeam(index) {
      this.appCardIndex = 0
      this.activeIndex = index
      this.appDataList = []
      if (this.activeIndex == 0) this.getFavorList()
      else this.getTeamList()
    },
    // 获取新的前三种列表
    async getNewList(typeId) {
      this.loading = true
      let { status, data } = await $Api.project.getListByType(typeId)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data !== null) {
          data.map((iItem) => {
            iItem.map((jItem) => {
              this.iconImgBox.map((appItem) => {
                if (appItem.id == jItem.id) {
                  jItem.iconimgUrl = appItem.iconimgUrl
                }
              })
            })
          })

          this.appDataList = data[0]
          if (this.appDataList.length == 1) this.showIndicatorPosition = 'none'
        }
        this.loading = false
      }
    },
    // 获取常用列表
    async getFavorList() {
      this.loading = true
      let { status, data } = await $Api.project.getListMyFavor()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data !== null) {
          data.map((iItem) => {
            iItem.map((jItem) => {
              this.iconImgBox.map((appItem) => {
                if (appItem.id == jItem.id) {
                  jItem.iconimgUrl = appItem.iconimgUrl
                }
              })
            })
          })
          this.appDataList = data
          if (this.appDataList.length == 1) this.showIndicatorPosition = 'none'
        }
        this.loading = false
      }
    },
    // 获取团队列表
    async getTeamList() {
      this.loading = true
      let { status, data } = await $Api.project.getListByTeam()
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        if (data !== null) {
          data.map((iItem) => {
            iItem.map((jItem) => {
              this.iconImgBox.map((appItem) => {
                if (appItem.id == jItem.id) {
                  jItem.iconimgUrl = appItem.iconimgUrl
                }
              })
            })
          })
          this.appDataList = data
          if (this.appDataList.length == 1) this.showIndicatorPosition = 'none'
        }
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss"></style>
