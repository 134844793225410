const { VUE_APP_BASEURL} = process.env;
export default {
  /**
   * 查询用户组列表
   */
  getGroupList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/user/user-group/group-list`,{params: data});
  },
   /**
   * 申请用户
   */
  goApply(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/user/user-group/apply`,params);
  },
  /**
   * 管理用户组列表
   */
  getGroupManageList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user-group/group-management-list`,{params:data});
  },
  /**
   * 允许用户申请Approve
   */
  allowApprove(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/approve`,params);
  },
  /**
   * 拒绝用户申请Approve
   */
  rejectApprove(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/reject`,params);
  },
  /**
   * 删除用户
   */
  deleteUser(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/delete  `,params);
  },

  /**
   * 查找组员
   */
  searchUserTab(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user-group/add-user-list`,{params:data});
  },
  /**
   * 添加组员
   */
  addUser(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/add-user`,params);
  },
  /**
   * 获取用户初次组信息
   */
  getUserGroup() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/user/user-group/notify`);
  },
  /**
   * 检测事件
   */
  clickMonitor(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/user/user-group/click-monitor`,{params:{clickStatus:id}});
  },
}
  