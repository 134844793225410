const { VUE_APP_BASEURL } = process.env
export default {
  /**
   * 获取角色列表
   */
  getRoleList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/role/list`, { params: data })
  },
  /**
   * 增加角色
   */
  addRole(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/role/add`, params)
  },

  /**
   * 账号类型下拉类表
   */
  getAccountTypeList() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user/account/option-list`)
  },
  /**
   * 公司品牌下拉列表
   */
  getOrgList(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user/org/option-list`, {
      params: { accountType: id },
    })
  },
  /**
   * 角色权限下拉列表
   */
  getRoleOptionList(data) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/access/option-list-by-role`, data)
  },

  /**
   * 查询权限列表
   *
   */
  getAccessProductList(id, groupId, keyword) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/list-by-project`, {
      params: { projectId: id, userGroupId: groupId, keyword: keyword },
    })
  },
  /**
   * 编辑角色信息
   */
  getRoleUpd(data) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/role/upd`, data)
  },
  /**
   * 获取角色信息
   */

  getRoleInfo(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/role/info`, {
      params: { roleId: id },
    })
  },
  /**
   * 获取用户角色类表
   */
  getAssignUserList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/role/assign/user-list`, {
      params: data,
    })
  },
  /**
   * 保存修改角色全向
   */
  saveAssign(data) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/role/assign-user`, data)
  },
  /**
   * 删除角色
   */

  detelRole(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/role/del`, params)
  },
}
