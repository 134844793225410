<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="78%"
      top="2%"
      id="detectId"
      :title="marginrGroupTitle"
      class="groupManagerList-detail-dialog home"
      @close="closed"
    >
      <div class="userList">
        <div class="groupBoxHeader">
          <el-input
            type="info"
            v-model="pageConfig.context"
            :placeholder="$t('dc:请输入用户组名称/用户组管理员')"
            style="width: 270px"
            clearable
            class="input-black"
            @keyup.enter.native="queryApi"
            @clear="queryApi"
          >
            <el-button
              class="btn-black"
              slot="append"
              @click="queryApi"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </div>
        <PocTable
          v-loading="loading"
          :data="tableData"
          :current-page.sync="pageConfig.pageNum"
          :total="pageConfig.total"
          :border="false"
          height="680"
          class="tabe-header-black"
          popper-class="popper-black"
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
        >
          <el-table-column
            v-for="item in columnOptions"
            :key="item.key"
            :align="item.align"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sorter="item.sortable"
            :min-width="item.width"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="item.key === 'statusName'">
                <span v-if="scope.row.actionId === 1">{{ scope.row[item.key] }}</span>
                <span v-else>-</span>
              </div>
              <div v-else-if="item.key === 'numbersOfMembers'">
                <el-tooltip
                  placement="left"
                  popper-class="memberClass"
                  v-if="scope.row.nameList !== null"
                >
                  <div slot="content">
                    <div
                      class="nameListBox"
                      v-for="(items, index) in scope.row.nameList"
                      :key="index"
                    >
                      {{ items }}
                    </div>
                  </div>
                  <span>{{ scope.row[item.key] }}</span>
                </el-tooltip>
                <span v-else>{{ scope.row[item.key] }}</span>
              </div>
              <span v-else>{{ scope.row[item.key] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('dc:操作')"
            width="130"
            align="center"
            class-name="ation"
            style="padding: 0"
          >
            <template slot-scope="scope">
              <div class="action-btn-group">
                <el-tooltip
                  v-if="STATUS_ENUM.i18n[scope.row.actionId] == 'Cancel'"
                  effect="dark"
                  :content="$t('dc:取消')"
                  placement="top-start"
                >
                  <i
                    class="poc-icon-info poc-size20 iconfont icon-oneui-contract-reject nicon"
                    @click="Cancel(scope.row)"
                  ></i>
                </el-tooltip>
                <el-tooltip
                  v-if="STATUS_ENUM.i18n[scope.row.actionId] == 'Apply'"
                  effect="dark"
                  :content="$t('dc:申请')"
                  placement="top-start"
                >
                  <i
                    class="poc-icon-info poc-size20 iconfont icon-oneui-contract-complete nicon"
                    @click="Apply(scope.row)"
                  ></i>
                </el-tooltip>
                <el-tooltip
                  v-if="STATUS_ENUM.i18n[scope.row.actionId] == 'Manage'"
                  effect="dark"
                  :content="$t('dc:管理')"
                  placement="top-start"
                >
                  <el-badge :hidden="scope.row.applyCount == 0" :value="scope.row.applyCount">
                    <i
                      class="poc-icon-info poc-size20 iconfont icon-oneui-contract-rollback nicon"
                      @click="Manage(scope.row)"
                    ></i>
                  </el-badge>
                </el-tooltip>
                <span v-if="STATUS_ENUM.i18n[scope.row.actionId] == 'Processing'" class="poc-size12"
                  >Processing</span
                >
              </div>
            </template>
          </el-table-column>
        </PocTable>
      </div>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :dWith="dWith"
      :title="title"
      :className="classNameOne"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: 'list',
  mixins: [$PCommon.TableMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    appProductId: {},
  },
  components: {
    UsuallyDialog,
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    appProductId() {},
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      show4Dialog: false,
      classNameOne: 'home',
      title: '',
      dWith: '380px',
      marginrGroupTitle: this.$t('dcH:用户组管理') + '/' + this.$t('dc:用户组列表'),
      content: '',
      tableData: [],
      pageConfig: {
        pageNum: 1,
        pageSize: 10,
        context: '',
        appId: this.appProductId,
        total: 10,
      },
      rowData: {},
      userGroupType: 0,
      showStatus: false,
    }
  },
  created() {
    this.$nextTick(() => {
      let classOneName = document.getElementById('detectId')
      classOneName.addEventListener(
        'click',
        (e) => {
          this.detect(e)
        },
        false,
      )
    })
  },
  destroyed() {},
  computed: {
    ...mapGetters(['managerStatus']),
    STATUS_ENUM() {
      return {
        i18n: {
          Apply: this.$t('dc:申请'),
          Manage: this.$t('dc:管理'),
          Pending: 'Pending',
          Cancel: this.$t('dc:取消'),
          0: 'Apply',
          1: 'Manage',
          2: 'Processing',
          3: 'Cancel',
        },
      }
    },
    columnConfig() {
      return [
        {
          key: 'groupId',
          name: 'No.',
          align: 'left',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 30, // 默认宽度，像素
        },
        {
          key: 'groupName',
          name: this.$t('dc:用户组名称'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
        {
          key: 'groupAdmin',
          name: this.$t('dc:管理员'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
        {
          key: 'desc',
          name: this.$t('dc:描述'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
        {
          key: 'c_time',
          name: this.$t('dc:创建时间'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
        {
          key: 'numbersOfMembers',
          name: this.$t('dc:用户组人数'),
          align: 'right',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 90,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initEvent() {
      this.queryApi()
    },
    async queryApi() {
      this.loading = true
      let { status, data } = await $Api.groupAdmin.getGroupList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.tableData = data.list
        this.tableData.map((item) => {
          if (item.nameList !== null && item.nameList) {
            this.$set(item, 'nameList', item.nameList.split(';'))
            if (item.nameList.length > 10) item.nameList.length = 10
          }
          if (this.actionId == 1) {
            this.$set(this.columnConfig[6], 'visible', false)
          }
        })
        this.pageConfig.total = data.total
        this.loading = false
      }
    },
    Cancel(row) {
      this.rowData = row
      this.title = this.$t('dc:提醒')
      this.dWith = '380px'
      ;(this.classNameOne = 'home'), (this.content = this.$t('dc:您确认退出该组吗?'))
      this.show4Dialog = true
      this.userGroupType = 2
    },
    Apply(row) {
      this.rowData = row
      this.dWith = '520px'
      this.title = this.$t('dc:提醒')
      ;(this.classNameOne = 'home testL'),
        (this.content = this.$t(
          'dc:您是否确认要申请该用户组？您的申请将由该组管理员审批，审批结果将会邮件告知您。',
        ))
      this.show4Dialog = true
      this.userGroupType = 0
    },
    Manage(row) {
      this.rowData = row
      this.$emit('groupMager', this.rowData)
    },
    confrom() {
      switch (this.userGroupType) {
        case 0:
          this.subApply(this.rowData)
          break
        // case 1:
        //    this.$router.push({name: 'user.usermanager',params:{groupId: this.rowData.groupId}})
        //   break;
        case 2:
          this.subApply(this.rowData)
          break
        default:
          break
      }
      this.$emit('changeManagerStatus')
    },
    async subApply(rowData) {
      rowData.userGroupId = rowData.groupId
      let { status, data } = await $Api.groupAdmin.goApply(rowData)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.dWith = '380px'
        this.show4Dialog = false
        this.$emit('getNotify')
        this.queryApi()
      }
    },

    closed() {
      this.show = false
      this.$emit('groupMager', { groupId: 0 })
      this.$emit('getNotify')
      $Api.groupAdmin.clickMonitor(1)
      let classOneName = document.getElementById('detectId')
      classOneName.removeEventListener('click', (e) => {
        this.detect(e)
      })
    },
    detect(e) {
      if (e.target.id == 'detectId') {
        $Api.groupAdmin.clickMonitor(0)
        this.$emit('getNotify')
      }
    },
  },
}
</script>
<style lang="scss">
.groupBoxHeader {
  margin-bottom: 20px;
  width: 100%;
  text-align: right;

  .el-input-group {
    padding-right: 16px;
  }
}

.userList {
  margin-top: -10px;
  // padding-bottom: 20px;
  padding-right: 0 !important;

  .el-badge__content {
    height: 14px;
    line-height: 14px;
    overflow: hidden;
    padding: 0 4px;
  }

  .el-badge__content.is-fixed {
    border-color: transparent;
    top: 8px;
    right: 20px;
    z-index: 1000;
  }
}

.action-btn-group {
  // margin-right: -16px;
}
</style>
