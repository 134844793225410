<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="60%"
      top="5%"
      :title="groupManagerTitle"
      :close-on-click-modal="false"
      class="groupManager-detail-dialog home"
      :class="otherClass"
      @close="closeManagerDialog"
    >
      <div class="userList">
        <!-- <template slot="headerTitle">
        <span class="font14">{{$t('dc:组管理者')}}</span>
        <span class="padRL6 font14">/</span>
        <span class="font12">{{$t('dc:人员列表')}}</span>
      </template>
      <template slot="headerGroup">
         <el-button type="" @click="goGroupList">{{$t('dc:返回')}}</el-button>
        <el-button type="primary" @click="onAddUser">{{$t('dc:添加用户')}}</el-button>
      </template> -->
        <div class="groupBoxHeader">
          <!-- <el-button class="btn-black marginR20 " @click="closeManagerDialog" >{{$t('dc:返回')}}</el-button> -->
          <el-input
            type="info"
            v-model="pageConfig.context"
            :placeholder="$t('dc:请输入账号名称')"
            style="width: 240px; margin-right: 10px"
            clearable
            class="input-black"
            @clear="queryApi"
            @keyup.enter.native="queryApi"
          >
            <el-button
              class="btn-black"
              slot="append"
              @click="queryApi"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <el-button class="btn-green" @click="onAddUser">{{ $t('dc:新增用户') }}</el-button>
        </div>
        <PocTable
          v-loading="loading"
          :data="tableData"
          :current-page.sync="pageConfig.pageNum"
          :total="pageConfig.total"
          :border="false"
          height="250"
          popper-class="popper-black"
          class="tabe-header-black"
          @size-change="pageSizeChange"
          @page-current-change="pageChange"
        >
          <el-table-column
            v-for="item in columnOptions"
            :key="item.key"
            :align="item.align"
            :prop="item.key"
            :label="item.name"
            :fixed="item.fixed"
            :sorter="item.sortable"
            :min-width="item.width"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="item.key === 'status'">
                <span v-if="scope.row.status === 0">Enable</span>
                <span v-if="scope.row.status === 1">Processing</span>
                <span v-if="scope.row.status === 2">Disable</span>
              </div>
              <span v-else>{{ scope.row[item.key] || scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('dc:操作')" width="140" align="center">
            <template slot-scope="scope">
              <div class="action-btn-group">
                <el-tooltip
                  v-if="scope.row.actionId == 2 || scope.row.actionId == 1"
                  effect="dark"
                  :content="$t('dc:审批通过')"
                  placement="top-start"
                >
                  <i
                    class="poc-icon-info nicon poc-size14 iconfont icon-oneui-right padding12"
                    @click="Approve(scope.row)"
                  ></i>
                </el-tooltip>
                <el-tooltip
                  v-if="scope.row.actionId == 2"
                  effect="dark"
                  :content="$t('dc:拒绝 ')"
                  placement="top-start"
                >
                  <i
                    class="poc-icon-info nicon poc-size14 iconfont icon-oneui-wrong padding12"
                    @click="Reject(scope.row)"
                  ></i>
                </el-tooltip>
                <el-tooltip
                  v-if="scope.row.actionId == 0"
                  effect="dark"
                  :content="$t('dc:删除')"
                  placement="top-start"
                >
                  <i
                    class="poc-icon-info nicon poc-size14 iconfont icon-oneui-delete padding12"
                    @click="Delete(scope.row)"
                  ></i>
                </el-tooltip>
                <i
                  v-if="scope.row.actionId == 3 || scope.row.actionId == 5"
                  class="poc-icon-info nicon poc-size14 iconfont padding12"
                  >-</i
                >
              </div>
            </template>
          </el-table-column>
        </PocTable>
      </div>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :className="className"
      :title="groupTitle"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
    <AddUserDialog
      v-if="showAddUserDialog"
      :visible.sync="showAddUserDialog"
      :managerGroupId="managerGroupId"
      @close="closeAdd"
    ></AddUserDialog>
  </div>
</template>
<script>
import UsuallyDialog from '@/views/dialog/usuallyDialog'
import AddUserDialog from '@/views/dialog/addUserDialog'
export default {
  components: {
    UsuallyDialog,
    AddUserDialog,
  },
  name: 'manager',
  mixins: [$PCommon.TableMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    managerGroupId: Number,
    managerGroupName: String,
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
    show4Dialog(v) {
      if (!v) this.initEvent()
      if (!v) this.$emit('changeManagerStatus')
    },
  },
  data() {
    return {
      show: this.visible,
      otherClass: this.className,
      className: 'home',
      loading: false,
      show4Dialog: false,
      showAddUserDialog: false,
      groupTitle: '',
      groupManagerTitle:
        this.managerGroupName + ' ' + this.$t('dc:用户组') + '/' + this.$t('dc:用户列表'),
      groupId: '',
      content: '',
      tableData: [],
      pageConfig: {
        groupId: '',
        context: '',
      },
      rowData: {
        groupId: '',
      },
      clickType: '',
    }
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'userId',
          name: 'No.',
          align: 'left',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 40, // 默认宽度，像素
        },
        {
          key: 'userName',
          name: this.$t('dc:账号名称'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
        {
          key: 'email',
          name: this.$t('dc:邮箱'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 180,
        },
        {
          key: 'status',
          name: this.$t('dc:状态'),
          align: 'left',
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: null,
        },
      ]
    },
    columnOptions() {
      return this.columnConfig.filter((n) => n.visible)
    },
  },
  mounted() {
    this.initData()
    this.initEvent()
  },
  methods: {
    initData() {
      this.groupId = this.pageConfig.groupId = this.rowData.groupId = this.managerGroupId
    },
    initEvent() {
      this.queryApi()
    },
    // 根据用户组Id获取用户
    async queryApi() {
      this.loading = true
      let { status, data } = await $Api.groupAdmin.getGroupManageList(this.pageConfig)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.tableData = data.list
        this.pageConfig.total = data.total
        this.$emit('getNotify')
      }
      this.loading = false
    },
    closeAdd() {
      this.$emit('changeManagerStatus')
      this.queryApi()
    },
    onAddUser() {
      this.showAddUserDialog = true
    },
    goGroupList() {
      this.$router.push({ name: 'user.list' })
    },
    Reject(row) {
      this.clickType = 2
      this.rowData = row
      this.groupTitle = this.$t('dc:提醒')
      this.content = this.$t('dc:您确定要拒绝此用户吗?')
      this.show4Dialog = true
    },
    Approve(row) {
      this.clickType = 1
      this.rowData = row
      this.groupTitle = this.$t('dc:提醒')
      this.content = this.$t('dc:您确定要添加该用户吗?')
      this.show4Dialog = true
    },
    Delete(row) {
      this.clickType = 0
      this.rowData = row
      this.groupTitle = this.$t('dc:提醒')
      this.content = this.$t('dc:您确定要删除此用户吗?')
      this.show4Dialog = true
    },
    confrom() {
      let groupId = this.groupId * 1
      let rowData = this.rowData
      switch (this.clickType) {
        case 0:
          this.userDelete({ groupId, ...this.rowData })
          break
        case 1:
          this.userApprove({ groupId, ...this.rowData })
          break
        case 2:
          this.usrReject({ groupId, ...this.rowData })
          break
        // case 4:
        //   this.$router.push({name: 'user.list'})
        //   break;

        default:
          break
      }
    },

    // 允许用户申请
    async userApprove(params) {
      let { status } = await $Api.groupAdmin.allowApprove(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show4Dialog = false
      }
    },
    // 拒绝用户申请
    async usrReject(params) {
      let { status } = await $Api.groupAdmin.rejectApprove(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show4Dialog = false
      }
    },
    // 删除用户
    async userDelete(params) {
      let { status } = await $Api.groupAdmin.deleteUser(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.show4Dialog = false
      }
    },

    closeManagerDialog() {
      this.show = false
      this.$emit('closeManager')
    },
  },
}
</script>
<style lang="scss">
.groupBoxHeader {
  width: 100%;
  text-align: right;

  .btn-green {
    margin-right: 16px;
  }
}

.userList {
  // height: 520px;
  // overflow-y: scroll;
  // margin-top: -10px;
  // padding-bottom: 20px;
  // padding-right: 4px;
}

.userList::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  // visibility: hidden;
}

// // 滚动条的滑块
.userList::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 2px;
  opacity: 0.5;
  // visibility: hidden;
}

.padding12 {
  padding: 0 12px;
}
</style>
