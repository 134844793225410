import config from '@/api/config'
import homeConfig from './homeConfig'

const { VueRouter, $sessionStorage, Vue } = $PCommon

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...homeConfig],
})

/**
 * 导航守卫 - 进入前
 * 清理url中的token参数，并存储token信息
 */
router.beforeEach((to, from, next) => {
  const query = JSON.parse(JSON.stringify(to.query))
  if (query.token) {
    $sessionStorage.setItem(config.USER_TOKEN_KEY, query.token)
    delete query.token
    next({ path: to.path, params: to.params, query })
  } else {
    next()
  }
})

export default router
