const FeedBackList = () => import('@/views/feedback/FeedBackList')
const SurveyList = () => import('@/views/feedback/SurveyList')

export default {
  routes: [
    {
      path: 'survey-list',
      name: 'feedbackcenter.surveylist',
      component: SurveyList,
      meta: {
        title: 'Survey List'
      },
    },
    {
      path: 'feedback-list',
      name: 'feedbackcenter.feedbacklist',
      component: FeedBackList,
      meta: {
        title: 'Feedback List'
      },
    },
    
  ],
};
