import config from './config'

const { VUE_APP_BASEURL, VUE_APP_SSOURL } = process.env
const { $sessionStorage } = $PCommon

export default {
  /**
   * 获取当前用户信息
   */
  getUserInfo() {
    return $Request.post(
      `${VUE_APP_BASEURL}/api/security/user/info?appName=${config.PROJECT_CODE}`,
      { appName: config.PROJECT_CODE },
    )
  },
  /**
   * 登录
   */
  login({ password, account, callbackUrl, appName }) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/acc-login`, {
      password,
      account,
      callbackUrl,
      appName,
    })
  },
  /**
   * 登出
   */
  logout() {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/logout`, { appName: config.PROJECT_CODE })
  },
  /**
   * 跳转Lionid登录
   */
  goLionid() {
    return `${VUE_APP_BASEURL}/api/security/lion-login`
  },
  /**
   * 获取当前用户所有权限列表
   */
  queryAccessAll(moduleName = null) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/function/access-list`, {
      params: {
        appName: 'account_maintain',
        moduleName,
      },
      noGroupId: true,
    })
  },
  /**
   * 重置密码发邮件
   */
  forgetPwd({ username, email }) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/user/forget-pwd`, {
      username,
      email,
    })
  },
  /**
   * 快速分配公司
   */
  assignAgency(id) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/user/assign-agency`, { agencyId: id })
  },
  /**
   * 新增用户
   */
  register(formData) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/acc-user/register`, formData)
  },
  /**
   * 设置用户语言
   */
  setLang(data) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/user/set-lang`, data)
  },
  /**
   * 修改密码
   */
  // changePassword(data) {
  //   return $Request.post(`${VUE_APP_BASEURL}/api/security/acc-user/register`,data)
  // },
  /**
   * 获取用户列表
   *
   */
  getUserList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user/list`, { params: data })
  },
  /**
   * 生成随机密码
   */
  randomPwd(userId) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user/random-pwd`, { userId })
  },
  /**
   * 禁用
   */
  updateStatus(userId, accountStatus) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user/update-status`, {
      userId,
      accountStatus,
    })
  },
  /**
   * 保存更新信息
   */
  update(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user/update`, params)
  },
  /**
   * 审批发邮件
   */
  approvedEmail(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user/mail/approved`, params)
  },

  /* 验证是否登录
   */
  loginCount(username) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/user/login-count?username=${username}`)
  },
  /**
   * 修改account密码
   *
   */
  changePwd(data) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/user/change-pwd`, data)
  },
  /**
   * 获取用户问卷调查结果
   */
  // researchQuest(data) {
  //   return $Request.get(`${VUE_APP_BASEURL}/api/security/user/research-quest`,{params:data});
  // },
  researchQuest(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/survey/pop-up`, { params: data })
  },
  /**
   * 消息已读
   */
  readMessage(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/message/read`, params)
  },
  /**
   * 获取用户默认的agencyId
   */
  agencySelect() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/user/acc-user/agency-select`)
  },
  /**
   * 修改品牌
   */
  changeBrand(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/user/brand/brand-select`, params)
  },
  /**
   * 获取当前用户所有权限
   */
  getAllAccess(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/details`, { params: data })
  },
  /**
   * 记录点击次数
   */
  loginNum(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/app/login-count`, { params: data })
  },
}
