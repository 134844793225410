import Home from '@/views/index'
import DeployHome from '@/views/deployHome'
// import GroupHome from '@/views/groupHome';
import Login from '@/views/Login'
// import AuthManagement from '@/views/auth/index';
// import OperationAccess from '@/views/operation/index';
import AuthManagementRoutes from './auth-management'
import OperationAccessRoutes from './operation-access'
import AccountUser from '@/views/accountUser/index'
import AccountUserRouter from './account-user'
import FeedbackCenter from './feedback-center'
import AppLink from '@/views/appLink'
// import productIndex from '@/views/productIndex'
import chatGpt from '@/views/chatGpt'
import Demo from '@/views/demo/demo'
const { CommonRouter } = $PCommon
// home路由配置
const homeConfig = [
  ...CommonRouter,
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Portal',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
      greenChannel: true,
      pathname: '/login',
    },
    query: {
      callbackUrl: '',
      appName: '',
    },
  },
  {
    path: '/applink',
    name: 'applink',
    component: AppLink,
    query: {
      callbackUrl: '',
      appName: '',
    },
  },
  {
    path: '/ChatGPT',
    name: 'chatGPT',
    component: chatGpt,
    meta: {
      title: 'ChatGPT',
    },
    query: {
      appId: '',
    },
  },
  {
    path: '/auth-management',
    name: 'authManagement',
    component: DeployHome,
    redirect: '/auth-management/account-list',
    children: [...AuthManagementRoutes.routes],
  },
  {
    path: '/operation-access',
    name: 'oerationAccess',
    component: DeployHome,
    redirect: '/operation-access/product-list',
    children: [...OperationAccessRoutes.routes],
  },
  {
    path: '/account-user',
    name: 'accountUser',
    component: AccountUser,
    redirect: '/account-user/apply',
    meta: {
      greenChannel: true,
      pathname: '/account-user',
    },
    children: [...AccountUserRouter.routes],
  },
  {
    path: '/feedback-center',
    name: 'feedbackCenter',
    component: DeployHome,
    redirect: '/feedback-center/survey-list',
    children: [...FeedbackCenter.routes],
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo,
  },
]
export default homeConfig
