<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="380px"
      top="14%"
      :close-on-click-modal="false"
      class="access-change-password-dialog home"
      :title="$t('dc:修改品牌')"
    >
      <el-form
        :model="brandDateForm"
        ref="brandDateForm"
        label-width="100px"
        label-position="left"
        class="demo-dynamic"
      >
        <el-form-item label-width="120px" :label="$t('dc:品牌名称')" class="" required>
          <el-select
            class="homeSelect"
            popper-class="popper-black"
            v-model="brandId"
            :placeholder="$t('dc:请选择')"
            style="color: #fff; width: 100%"
          >
            <el-option
              v-for="item in brandList"
              :key="item.brandId"
              :label="item.brandName"
              :value="item.brandId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" style="text-align: right">
        <el-button class="btn-black" @click="show = false">{{ $t('dc:取消') }}</el-button>
        <el-button class="btn-green" @click="submitForm('brandDateForm')">{{
          $t('dc:确认')
        }}</el-button>
      </span>
    </el-dialog>
    <UsuallyDialog
      v-if="show4Dialog"
      :visible.sync="show4Dialog"
      :title="title"
      :className="classNameOne"
      :content="content"
      @confrom="confrom"
    ></UsuallyDialog>
  </div>
</template>

<script>
import config from '@/api/config'
import UsuallyDialog from '@/views/dialog/usuallyDialog'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'accessDialog',
  components: {
    UsuallyDialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: String,
  },
  data() {
    return {
      loading: false,
      show: this.visible,
      show4Dialog: false,
      classNameOne: 'home',
      title: this.$t('dc:提醒'),
      content: this.$t('dc:你确认改变你的品牌？'),
      brandList: [],
      agencyId: {},
      brandId: null,
      brandDateForm: {},
    }
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v)
    },
    visible(v) {
      this.show = v
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    ...mapActions(['clearUserInfo']),
    initEvent() {
      // this.brandDateForm.brandId = this.userInfo.brandId
      this.get4changeBrand({})
      this.getBrandList()
    },
    async getBrandList() {
      const { status, data } = await $Api.project.getBrandList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) this.brandList = data
    },
    onCancel() {
      this.show = false
    },
    submitForm(formName) {
      if (this.brandId === '' || this.brandId === null) {
        return this.$message({
          showClose: true,
          message: this.$t('dc:请选择'),
          type: 'warning',
        })
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.show4Dialog = true
        } else {
          return false
        }
      })
    },
    confrom() {
      if (this.brandId.brandId) this.brandDateForm.brandId = this.brandId.brandId
      else this.brandDateForm.brandId = this.brandId
      this.get4changeBrand(this.brandDateForm)
    },
    async get4changeBrand(params) {
      const { status, data } = await $Api.user.changeBrand(params)
      if (status === this.$RESPONSE_STATUS.SUCCESS) {
        this.brandId = data.brandId
        if (params.brandId !== undefined) {
          this.show4Dialog = false
          this.show = false
          this.$message({
            showClose: true,
            message: 'success',
            type: 'success',
          })
        }
      }
    },
  },
}
</script>
<style lang="scss">
.marginB50 {
  margin-bottom: 50px !important;
}
</style>
