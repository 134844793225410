const { VUE_APP_BASEURL} = process.env;
export default {
   /**
   * 查询chatGPT信息
   */
   getChatGPTMsg(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/chat-gpt/chat`,params,{timeout:5 * 60 * 1000});
  },
  /**
   * 获取chatGPT是否展示
   */
  getAuthority(){
    return $Request.get(`${VUE_APP_BASEURL}/api/security/chat-gpt/authority`);
  },
  /**
   * 添加新的会话
   */
  addNewChat(){
    return $Request.get(`${VUE_APP_BASEURL}/api/security/chat-gpt/chat/add`);
  },
  /**
   * 删除一个会话
   */
  delChat(id){
    return $Request.get(`${VUE_APP_BASEURL}/api/security/chat-gpt/chat/del`,{params: {chatId: id}});
  },
  /**
   * 获取chat会话列表
   */
  getChatList(){
    return $Request.get(`${VUE_APP_BASEURL}/api/security/chat-gpt/chat/list`);
  },
  /**
   * 根据chat的id获取当前的会话
   */
  getChatDetail(id){
    return $Request.get(`${VUE_APP_BASEURL}/api/security/chat-gpt/chat/info`,{params: {chatId: id}});
  },
  /**
   * 获取消费金额
   */
  getChatMoney(){
    return $Request.get(`${VUE_APP_BASEURL}/api/security/chat-gpt/get-cost`);
  }
}