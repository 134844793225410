const AccountList = () => import('@/views/auth/AccountList')
const AccessList = () => import('@/views/auth/AccessList')
const IrisList = () => import('@/views/auth/IRISList')

export default {
  routes: [
    {
      path: 'account-list',
      name: 'authManagement.AccountList',
      component: AccountList,
      meta: {
        title: 'Account List',
      },
    },
    {
      path: 'access-list',
      name: 'authManagement.AccessList',
      component: AccessList,
      meta: {
        title: 'Access Application List',
      },
    },
    {
      path: 'iris-list',
      name: 'authManagement.IrisList',
      component: IrisList,
      meta: {
        title: 'Access Iris List',
        accessCode: 'account_maintain.AccountManagement.IRISMappingList.view',
      },
    },
  ],
}
