<template>
  <div class="childDiv">
    <div v-letter:uppercase>hello world</div>
  </div>
</template>
<script>
  export default{
    data(){
      return{
        num:20
      }
    },
    directives: {
      letter: {
        mounted(el, binding) {
          const text = el.innerHTML
          if (binding.arg === 'uppercase') {
            el.innerHTML = text.toUpperCase()
          } else if (binding.arg === 'lowercase') {
            el.innerHTML = text.toLowerCase()
          } else {
            el.innerHTML = text.split('')
          }
        }
      }
  }
  }
</script>

<style>
.childDiv{
  width: 200px;
  height: 400px;
  border: 1px solid red;
}
</style>