<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="780px"
      top="5%"
      :class="className"
      :close-on-click-modal="false"
      class="intelligence-dialog"
    >
     <div class="intelligence-dialog-box">
        <div class="intelligence-dialog-box-left">
          <ul class="scroll-box">
            <li v-for="(item,index) in barData" :key="index" :class="{'active':activeIndex === index}" @click="changeBar(index)">
              <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start">
              <span>{{ item.name }}</span>
              </el-tooltip>
            </li>
          </ul>
        </div>
        <div class="intelligence-dialog-box-right">
          <!-- <div class="intelligence-dialog-box-right-header">
            <span class="title">{{ $t('dc:第三方工具') }}</span>
            <el-tabs v-model="activeName" @tab-click="handleClick" style="width:100%">
              <el-tab-pane :label="$t('dc:什么是IT')" name="INTRODUCTION" :disabled="respircesData.attribute1 == null"></el-tab-pane>
              <el-tab-pane :label="$t('dc:为什么要使用它')" name="WHYUSEIT" :disabled="respircesData.attribute2 == null"></el-tab-pane>
              <el-tab-pane :label="$t('dc:申请权限的方式')" name="ACCESS" :disabled="respircesData.attribute3 == null"></el-tab-pane>
              <el-tab-pane :label="$t('dc:附件')" name="FILE" :disabled="!showUl"></el-tab-pane>
            
            </el-tabs>
          </div> -->
          <div class="intelligence-dialog-box-right-main" ref="contentBox" @scroll='showOut' v-loading="loading">
            <div class="content" v-if="!loading" v-show="respircesData.attribute1 !== null || respircesData.attribute2 !== null|| showUl">
              <div id="INTRODUCTION" v-show="respircesData.attribute1 !== null">
                <h6>{{ $t('dc:produc描述') }} <i class="borderGreen"></i></h6>
                <p :class="{'noData':respircesData.attribute1 == null}" v-html="respircesData.attribute1"> </p>
              </div>
              <div id="WHYUSEIT" v-show="respircesData.attribute2 !== null">  
                 <h6>{{$t('dc:用途')}} <i class="borderGreen"></i></h6>
                <p :class="{'noData':respircesData.attribute2 == null}" v-html="respircesData.attribute2"></p>
              </div>
              <div id="ACCESS" v-show="respircesData.attribute3 !== null">  
                 <h6>{{$t('dc:申请权限的方式')}} <i class="borderGreen"></i></h6>
                <p :class="{'noData':respircesData.attribute2 == null}" v-html="respircesData.attribute2"></p>
              </div>
              <div id="FILE" v-show="showUl">
                <h6>{{$t('dc:附件')}} <i class="borderGreen"></i></h6>
                <ul class="scroll-file-box" v-if="showUl">
                  <li v-for="(item,index) in respircesData.files" :key="index">
                      <span>{{item.description}}</span>
                      <el-button class="btn-green borderR" type="primary" @click="downLoadFile(item)">{{$t('dc:下载')}}</el-button>
                  </li>
                </ul>
                <p v-else class="noData marginB"></p>
              </div>

            </div>
            <div class="productNoDataImg" v-show="respircesData.attribute1 == null && respircesData.attribute2 == null &&  !showUl">
              <img :src="noData" alt="">
            </div>
          </div>
        </div>
     </div>
    </el-dialog>
  </div>
</template>
<script>
import noData from "@/assets/img/noData.png";
export default {
  name: 'intelligenceDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    className: String,
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
    
  },
  data() {
    return {
      noData,
      show: this.visible,
      activeName:"INTRODUCTION",
      activeIndex:0,
      barData: [],
      respircesData:{},
      showUl:true,
      loading:false,
      showNoDataImg: true,
      scrollTab:{
        one:{
          scrollHeight:''
        },
        two:{
          scrollHeight:''
        },
        three:{
          scrollHeight:''
        },
        four:{
          scrollHeight:''
        }
       
      }
    }
  },
  mounted() {
    this.initEvent()
  },
  methods: {
    initData(){
      if(document.getElementById('INTRODUCTION') !== null) this.scrollTab.one.scrollHeight = document.getElementById('INTRODUCTION').offsetTop +100
      if(document.getElementById('WHYUSEIT') !== null) this.scrollTab.two.scrollHeight = document.getElementById('WHYUSEIT').offsetTop + 100
      if(document.getElementById('ACCESS') !== null) this.scrollTab.three.scrollHeight = document.getElementById('ACCESS').offsetTop +100
      if(document.getElementById('FILE') !== null) this.scrollTab.three.scrollHeight = document.getElementById('FILE').offsetTop+100
    },
    initEvent() {
      this.getResourceListData()
    },
    async getResourceListData() {
      this.showUl = true
      this.loading = true
      const {status,data} = await $Api.project.getResourceList()
      if(status === this.$RESPONSE_STATUS.SUCCESS) {
        this.barData = data
        this.loading = false
      }
      this.respircesData = this.barData[this.activeIndex]

      if(this.respircesData.files.length == 0)this.showUl = false
      if(this.respircesData.attribute1 == null && this.respircesData.attribute2 == null && this.respircesData.files.length == 0) this.showNoDataImg = false
      else this.showNoDataImg = false
      this.initBarActive()
    },
    changeBar(index) {
      this.showUl = true
      this.activeName="INTRODUCTION"
      this.activeIndex = index
      this.$refs.contentBox.scrollTop = document.getElementById('INTRODUCTION').offsetTop - 18
      this.respircesData = this.barData[index]
      if(this.respircesData.files.length == 0)this.showUl = false
      if(this.respircesData.attribute1 == null && this.respircesData.attribute2 == null && this.respircesData.attribute3 && this.respircesData.files.length == 0) this.showNoDataImg = false
      else this.showNoDataImg = false
      this.initBarActive()

    },
    initBarActive(){
      if(this.respircesData.attribute1 !== null) return this.activeName = "INTRODUCTION"
      if(this.respircesData.attribute2 !== null) return this.activeName = "WHYUSEIT"
      if(this.respircesData.attribute3 !== null) return this.activeName = "ACCESS"
      if(this.respircesData.files.length !== 0) return this.activeName = "FILE"
    },
    handleClick(tab){
      this.activeName = tab.name
      this.$refs.contentBox.scrollTop = document.getElementById(tab.name).offsetTop  -18
    },
    showOut(e){
      this.initData()
      if(e.srcElement.scrollTop < this.scrollTab.one.scrollHeight) this.activeName = 'INTRODUCTION'
      else if(this.scrollTab.one.scrollHeight < e.srcElement.scrollTop && e.srcElement.scrollTop<this.scrollTab.two.scrollHeight) {
        if(this.scrollTab.two.scrollHeight == '') return
        this.activeName = 'WHYUSEIT'
      }else if(this.scrollTab.two.scrollHeight < e.srcElement.scrollTop && e.srcElement.scrollTop<this.scrollTab.three.scrollHeight) {
        if(this.scrollTab.three.scrollHeight == '') return
        this.activeName = 'ACCESS'
      }else{
        if(this.scrollTab.four.scrollHeight == '') return
        this.activeName = 'FILE'
      }
    },
    downLoadFile(item){
      window.location.href = item.url
    }
  }
}
</script>
<style lang="scss">
.intelligence-dialog{
  &-box{
    display: flex;
   
    &-left{
      li{
        color: #96979A;
        display: block;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        padding:  0 16px;
        border-bottom: 1px solid #000;
      }
      li.active{
       
        color: #fff;
        background-color: #226906;
      }
    }
    &-right{
      padding-left: 16px;
      &-header{
        height: 70px;
        .title{
          display: block;
          padding: 16px 0 8px 10px;
        }
      }
      &-main{
        position:absolute;
        width:70.5%;
        top: 0;
        bottom:0;
        padding-top: 0;
        overflow-y: auto;
        overflow-x: hidden;
        ul li{
          height: 44px;
          background: #414141;
          border-radius: 22px;
          margin-bottom: 8px;
          padding-left: 32px;
          padding-right: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
         
          span{
            font-size: 14px;
          }
        }
      }
      .content{
          display: block;
          padding: 0 6px 0 8px;
          h6{
            margin: 18px 0;
            font-size: 14px;
            color: #fff;
          }
          p{
            display: block;
            font-size: 12px;
            color: #fff;
            // overflow-wrap: break-word;
            word-break:break-word;
          }
      }
      
    }
  }
  p.noData {
    color: #606266;
  }
  .marginB{
    margin-bottom: 440px;
  }
  .borderGreen{
    display: inline-block;
    width: 4px;
    height: 12px;
    margin-left: 10px;
    background: #4AE30D;
  }
  .scroll-box{
    height: 540px;
    width: 208px;
    padding-right: 6px;
    overflow-y: scroll;
    overflow-x: hidden;
    li{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .scroll-file-box{
    // height: 280px;
    margin-bottom:140px ;
    padding-right: 8px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .productNoDataImg img{
    display: block;
    width: 260px;
    margin: 100px auto;
  }
  ul::-webkit-scrollbar {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    }
    ul::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 5px rgba(74, 227, 13, 1);;
    background: rgba(74, 227, 13, 1);
    visibility: inherit;
    }
    ul::-webkit-scrollbar-track {/*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
    border-radius: 4px;
    background: rgba(102, 104, 107, 1);
    // background: transparent;
    }

    .intelligence-dialog-box-right-main::-webkit-scrollbar {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    }
    .intelligence-dialog-box-right-main::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 5px rgba(74, 227, 13, 1);;
    background: rgba(74, 227, 13, 1);
    }
    .intelligence-dialog-box-right-main::-webkit-scrollbar-track {/*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
    border-radius: 4px;
    // background: rgba(102, 104, 107, 1);
    background: transparent;
    }

}
</style>

