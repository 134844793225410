const { VUE_APP_BASEURL} = process.env;
export default {
  /**
   * 查询弹出信息
   */
  getGroupMsg() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/user/prompt-group-admin`);
  },
  /**
   * 获取用户列表
   */
  getGroupList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user-group/list`,{params: data});
  },
  /**
   * 添加group
   */
  addGroup(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/add`,params);
  },
   /**
   * 修改group
   */
  editGroup(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/upd-info`,params);
  }, 
  selectMemberUpd(params){
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/upd-member`,params);
  },
  /**
   * 修改group新街口
   */
  editGroupInfo(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/upd`,params);
  },
  /**
   * 获取用户列表
   */
  getUserList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user-group/user-list`,{params: data});
  },
  /**
   * 获取邀请用户列表
   */
  getUserSelectList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user-group/user-select-list`,{params: data});
  },
  /**
   * 获取组详细信息
   */
  getGrouoInfo(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user-group/info`,{params: data});
  },
    /**
   * 获取组详细信息
   */
  getInvitationMail(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/user-group/invitation-mail`,params);
  },
    /**
   * 获取组详细信息
   */
  getAccessInfo(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user-group/access-info`,{params:data});
  },
      /**
   * 修改用户组信息
   */
  saveUpdateAccess(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/update-access`,params);
  },
  /**
   * 修改group状态
   */
  updataStatus(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/update-status`,params);
  },
  /**
   * 获取client列表
   */
  getAccessClientList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/client-list`,{params:data});
  },
  /**
   * /获取Divison下拉列表
   */
  getDivisonList() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/division-list`);
  },
  /**
   * 获取brand下拉列表
   */
  getBrandList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/access/brand-list`,{params:data});
  },
  /**
   * 确认添加权限
   */
  confirmAccess(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/access/confirm`,params);
  },
  /**
   * 用户组可视化转化
   */
  upDataVisible (params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user-group/update-visible`,params);
  }
}