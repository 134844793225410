import brandradar from '@/assets/iconimg/brandradar.png'
import autolab from '@/assets/iconimg/autolab.png'
import creativevault from '@/assets/iconimg/creativevault.png'
import modelcentle from '@/assets/iconimg/modelcenter.png'
import nova from '@/assets/iconimg/nova.png'
import tardlls from '@/assets/iconimg/tardlls.png'
import trace from '@/assets/iconimg/trace.png'
import portal from '@/assets/iconimg/portal.png'
import camp from '@/assets/iconimg/camp.png'

import cityradar from '@/assets/iconimg/city_radar.png'
import yangtze from '@/assets/iconimg/yangtze.png'
import pacepanel from '@/assets/iconimg/pacepanel.png'
import cleanroom from '@/assets/iconimg/cleanroom.png'
import fluency from '@/assets/iconimg/_fluency.png'
import touchpointsroi from '@/assets/iconimg/touchpoints_roi.png'
import tardiisprecision from '@/assets/iconimg/tardiis_precision.png'
import rolmodeller from '@/assets/iconimg/roimodeller.png'
import scenarioplanner from '@/assets/iconimg/scenario_planner.png'
import exhxtool from '@/assets/iconimg/exhx_tool.png'
import enova from '@/assets/iconimg/e_nova.png'
import singlesourcepanel from '@/assets/iconimg/single_source_panel.png'

import loreal from '@/assets/iconimg/loreal.png'
import sav from '@/assets/iconimg/sav.png'
import titan from '@/assets/iconimg/titan.png'
import jira from '@/assets/iconimg/jira.png'
import mdm from '@/assets/iconimg/mdm.png'
import publicisci from '@/assets/iconimg/publicisci.png'
import timesheet from '@/assets/iconimg/timesheet.png'

import bmw from '@/assets/iconimg/bmw.png'
import huawei from '@/assets/iconimg/huawei.png'

import reachtool from '@/assets/iconimg/reach_tool.png'

import fusion from '@/assets/iconimg/fusion.png'

import circlemediaalliance from '@/assets/iconimg/circle_media_alliance.png'
import touchpointsrol from '@/assets/iconimg/touchpoints_rol.png'

import jirang from '@/assets/iconimg/jirang.png'

import pulse from '@/assets/iconimg/pulse.png'

var appProListArr = [
  {
    id: 4,
    iconimgUrl: autolab,
  },
  {
    id: 5,
    iconimgUrl: brandradar,
  },
  {
    id: 6,
    iconimgUrl: cityradar,
  },
  {
    id: 7,
    iconimgUrl: creativevault,
  },
  {
    id: 12,
    iconimgUrl: trace,
  },
  {
    id: 13,
    iconimgUrl: titan,
  },
  {
    id: 14,
    iconimgUrl: mdm,
  },
  {
    id: 17,
    iconimgUrl: timesheet,
  },
  {
    id: 18,
    iconimgUrl: modelcentle,
  },
  {
    id: 20,
    iconimgUrl: tardlls,
  },
  {
    id: 21,
    iconimgUrl: publicisci,
  },
  {
    id: 22,
    iconimgUrl: jira,
  },
  {
    id: 23,
    iconimgUrl: nova,
  },
  {
    id: 25,
    iconimgUrl: creativevault,
  },
  {
    id: 26,
    iconimgUrl: portal,
  },
  {
    id: 27,
    iconimgUrl: loreal,
  },
  {
    id: 28,
    iconimgUrl: sav,
  },
  {
    id: 29,
    iconimgUrl: camp,
  },
  {
    id: 30,
    iconimgUrl: creativevault,
  },
  {
    id: 39,
    iconimgUrl: yangtze,
  },
  {
    id: 40,
    iconimgUrl: pacepanel,
  },
  {
    id: 41,
    iconimgUrl: cleanroom,
  },
  {
    id: 42,
    iconimgUrl: rolmodeller,
  },
  {
    id: 43,
    iconimgUrl: fluency,
  },
  {
    id: 44,
    iconimgUrl: touchpointsroi,
  },
  {
    id: 45,
    iconimgUrl: tardiisprecision,
  },
  {
    id: 46,
    iconimgUrl: scenarioplanner,
  },
  {
    id: 47,
    iconimgUrl: exhxtool,
  },
  {
    id: 48,
    iconimgUrl: enova,
  },
  {
    id: 49,
    iconimgUrl: bmw,
  },
  {
    id: 53,
    iconimgUrl: huawei,
  },
  {
    id: 65,
    iconimgUrl: reachtool,
  },
  {
    id: 66,
    iconimgUrl: fusion,
  },
  {
    id: 68,
    iconimgUrl: circlemediaalliance,
  },
  {
    id: 71,
    iconimgUrl: touchpointsrol,
  },
  {
    id: 72,
    iconimgUrl: jirang,
  },
  {
    id: 74,
    iconimgUrl: nova,
  },
  {
    id: 77,
    iconimgUrl: pulse,
  },
]

var appIntListArr = [
  {
    id: 5,
    iconimgUrl: autolab,
  },
  {
    id: 8,
    iconimgUrl: brandradar,
  },
  {
    id: 9,
    iconimgUrl: cityradar,
  },
  {
    id: 10,
    iconimgUrl: creativevault,
  },
  {
    id: 16,
    iconimgUrl: timesheet,
  },
  {
    id: 17,
    iconimgUrl: trace,
  },
  {
    id: 18,
    iconimgUrl: titan,
  },
  {
    id: 20,
    iconimgUrl: mdm,
  },
  {
    id: 26,
    iconimgUrl: tardlls,
  },
  {
    id: 31,
    iconimgUrl: modelcentle,
  },
  {
    id: 33,
    iconimgUrl: nova,
  },
  {
    id: 34,
    iconimgUrl: publicisci,
  },
  {
    id: 35,
    iconimgUrl: jira,
  },
  {
    id: 36,
    iconimgUrl: creativevault,
  },
  {
    id: 37,
    iconimgUrl: creativevault,
  },
  {
    id: 41,
    iconimgUrl: creativevault,
  },
  {
    id: 43,
    iconimgUrl: tardlls,
  },
  {
    id: 44,
    iconimgUrl: portal,
  },
  {
    id: 45,
    iconimgUrl: loreal,
  },
  {
    id: 49,
    iconimgUrl: camp,
  },
  {
    id: 52,
    iconimgUrl: creativevault,
  },
  {
    id: 56,
    iconimgUrl: yangtze,
  },
  {
    id: 57,
    iconimgUrl: pacepanel,
  },
  {
    id: 58,
    iconimgUrl: cleanroom,
  },
  {
    id: 59,
    iconimgUrl: sav,
  },

  {
    id: 60,
    iconimgUrl: fluency,
  },
  {
    id: 62,
    iconimgUrl: touchpointsroi,
  },
  {
    id: 63,
    iconimgUrl: tardiisprecision,
  },

  {
    id: 66,
    iconimgUrl: rolmodeller,
  },
  {
    id: 67,
    iconimgUrl: scenarioplanner,
  },
  {
    id: 70,
    iconimgUrl: exhxtool,
  },
  {
    id: 71,
    iconimgUrl: enova,
  },
  {
    id: 72,
    iconimgUrl: singlesourcepanel,
  },
  {
    id: 75,
    iconimgUrl: bmw,
  },
  {
    id: 83,
    iconimgUrl: reachtool,
  },
  {
    id: 85,
    iconimgUrl: camp,
  },
  {
    id: 86,
    iconimgUrl: circlemediaalliance,
  },
  {
    id: 90,
    iconimgUrl: nova,
  },
  {
    id: 94,
    iconimgUrl: pulse,
  },
]

function getAllData() {
  if (process.env.NODE_ENV === 'production') return appProListArr
  return appIntListArr
}

export default getAllData
