import jira from "@/assets/iconimg/jira.png";
import publicisci from "@/assets/iconimg/publicisci.png";
import timesheet from "@/assets/iconimg/timesheet.png";
var appNoProListArr = [{
  id: 22,
  iconimgUrl: jira
},{
  id: 17,
  iconimgUrl: timesheet
},{
  id: 21,
  iconimgUrl: publicisci
}]
var appNoIntListArr = [{
  id: 35,
  iconimgUrl: jira
},{
  id: 34,
  iconimgUrl: publicisci
},{
  id: 16,
  iconimgUrl: timesheet
}]
function getNoData() {
  if (process.env.NODE_ENV === 'production') return appNoProListArr
  return appNoIntListArr
}
export default getNoData