<template>
  <div class="sso-management app-link">
    <el-dialog
      :visible.sync="show"
      width="300px"
      top="15%"
      :show-close="false"
      :close-on-click-modal="false"
      class="app-link-dialog"
      :class="otherClass"
    >
      <div slot="title">
        <span class="applinkTile">{{ $t('dc:选择版本') }}</span>
      </div>
      <div class="applinkContent fontB6">
        <ul>
          <li v-for="item in linkList" @click="goLink(item)">
            <div>{{ item.name }}</div>
          </li>
        </ul>
      </div>
    </el-dialog>

    <!-- <poc-container class="sm-container">
      <template slot="logo">
        <img :src="logoImg" style="height:36px;"/>
         <img :src="ssoLogoImg" style="height:33px;margin-bottom:-5px;"/>
      </template>
      <HeaderRightGroup slot="rightGroup" />
      <div class="sso-management-body">
        <router-view />
      </div>
    </poc-container> -->
  </div>
</template>

<script>
import logoImg from '@/assets/img/log.png'
import ssoLogoImg from '@/assets/img/ssolog.png'
import HeaderRightGroup from '@/components/HeaderRightGroup'
import config from '@/api/config'
export default {
  name: 'AppLink',

  components: {
    HeaderRightGroup,
  },

  data() {
    return {
      logoImg,
      ssoLogoImg,
      show: true,
      linkList: [],
      otherClass: 'home',
      token: this.$sessionStorage.getItem(config.USER_TOKEN_KEY),
    }
  },
  computed: {},
  mounted() {
    this.linkList = this.$sessionStorage.getItem('appLinkList')
  },
  methods: {
    goLink(item) {
      this.show = false
      window.location.href = `${item.link}?token=${this.token}`
    },
  },
}
</script>

<style lang="scss">
// .sso-management {
//   height: 100%;
// }
// .sso-management.app-link .sso-management-body{
//   padding: 0 2px;
// }
// .common-page {
//   padding: $MP16 0;
// }
.app-link-dialog .el-dialog__body div.fontB6 {
  word-break: break-word;
}
.applinkTile {
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.applinkContent {
  ul {
    display: block;
  }
  li {
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: rgba(255, 255, 255, 0.2);
    margin-bottom: 12px;
    border-radius: 8px;
    div {
      color: #fff;
      font-weight: 500;
    }
  }
  li:hover {
    cursor: pointer;
    background: rgba(89, 226, 0, 1);
    div {
      color: #000;
      font-weight: 600;
    }
  }
}
</style>
