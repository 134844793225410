<template>
    <div>
        <el-dialog :visible.sync="show" width="470px" top="10%" :show-close="true" :close-on-click-modal="false"
            modal-append-to-body class="goLink-dialog home" @close="closeAgency">
            <div slot="title" class="linkBox">
                <i class="el-icon-warning-outline poc-size32"></i>
                <div>
                    <div class="email">
                        <span>{{ $t('dc:用于访问应用程序和使用顾问。请联系') }}</span> <span class="green">{{ email }} </span><span>{{ $t('dc:寻求帮助') }}</span>

                    </div>
                    <div class="msg">{{ $t('dc:之前需要通过电子邮件批准您的直线经理的访问权限') }}</div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btn-green" type="primary" @click="gotoUrl">{{ $t('dc:去首页') }}</el-button>
            </div>
        </el-dialog>

    </div>
</template>
<script>
export default {
    name: 'golink',
    components: {
    },
    data() {
        return {
            show: this.visible,
            show4Dialog: false,
            email: `<joseph.hu@publicismedia.com>`,

            homeClass: 'home',
            groupTitle: '',
            groupContent: '',

        }
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        itemData: {
            type: String,
            default: ''
        },
        showGoLinkDialog: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        show(v) {
            this.$emit("update:visible", v);
        },
        visible(v) {
            this.show = v;
        },
    },
    mounted() {

    },
    methods: {
        async closeAgency() {
            this.$emit('closeAgency')
        },
        gotoUrl() {
            window.location.href = this.itemData
        },
        closeGroup() { },
        confrom() {
            this.quertAssignAgency()
        }
    }
}
</script>

<style lang="scss">
.goLink-dialog.home {
    color: #fff;
    font-size: 14px;
   
    .el-dialog__body{
        // display: none;
    }
    .el-dialog__header .email span,.el-dialog__header .msg{
     font-weight: 500;
    }
    .el-dialog__footer{
        text-align: center;
        padding-top: 0;
    }
    i.el-icon-warning-outline{
        margin-right: 16px;
    }
    .linkBox {

        display: flex;

    }
    .email{
        margin-bottom: 16px;
    }
    .green{
        color: #48E30A;
    }
    .msg{
        width: 390px;
        font-style:italic;
    }
}
</style>
