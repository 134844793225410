<template>
    <div class="carousel-flex" :class="{'bigImg':className =='bigImg'}">
      <i class="iconfont icon-oneui-carouse-left imgLeft" style="margin-top:-20px;" :class="{'showToggle':carouselIndex>0}"  @click="imageClick('left')" ></i>
      <el-carousel ref="cardShow" trigger="click" arrow="never"  indicator-position="none" @change="changeCarousel"  :initial-index="carouselIndex" :autoplay="false" :loop="false" :height="carouseHeight" >
          <el-carousel-item v-for="(numItem,index) in appList" :key="index">
            <div class="carousel-box">
              <div class="carousel-item"  @click="goProjectDetail(item)" v-for="(item,itemIndex) in numItem" :key="itemIndex">
                <div class="carousel-img" >
                  <img  :src="item.snapshots" alt>
                </div>
                <span class="carousel-title">{{item.name }}</span>
              </div>
            </div>
          </el-carousel-item>
      </el-carousel>
      <i class="iconfont icon-oneui-carouse-right imgRight"  style="margin-top:-20px;"  :class="{'showToggle':carouselIndex<appList.length -1}"   @click="imageClick('right')"></i>
    </div>
</template>
<script>
export default {
  name:'carouselTmp',
  props:{
    type:{
      type:String,
      default:'home'
    },
    className:{
      type:String,
      default:''
    },
    appList: {
      type: Array,
      default: []
    }
  },
  data() {
    return{
      carouseHeight:'80px',
      carouselIndex:0
    }
  },
  methods: {
    changeCarousel(v) {
      this.carouselIndex = v
    },
    imageClick(val){
      if (val == 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    goProjectDetail(item) {
      window.open(item.url,'_blank')
    }
  }

}
</script>

<style lang="scss">
</style>
