<template>
  <div class="box">
    <!-- <template>
    <div class="typer">
      <div class="typer-content">
        <p class="typer-static">I'm&nbsp;</p>
        <p class="typer-dynamic">
          <span class="cut">
            <span class="word" v-for="(letter,index) in words" :key="index">{{letter}}</span>
          </span>
          <span class="typer-cursor"></span>
        </p>
      </div>
    </div>
</template> -->
    <!-- <div class="markdown-body">
    <vue-typed-js
      v-if="showCursor"
      class="desc "
      :shuffle="false"
      :strings="typingTexts"
      :loop="false"
      :startDelay="30"
      :typeSpeed="10"
      :backSpeed="50"
    >
  <span class="typing"></span>
</vue-typed-js>
</div> -->

    <markdownRender :text="strValue" :showCursor="showCursor"></markdownRender>
    <button @click="addStr">点我</button>
    <child></child>
    <!-- <el-scrollbar ref="myScrollbar"  style="height: 600px">
    
    <div style="height: 700px;"></div>
    </el-scrollbar> -->

    <!-- <div class="column1">
        <div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="用户管理" name="1">用户管理</el-tab-pane>
              <el-tab-pane label="配置管理" name="2">配置管理</el-tab-pane>
              <el-tab-pane label="角色管理" name="3">角色管理</el-tab-pane>
              <el-tab-pane label="定时任务补偿" name="4">定时任务补偿</el-tab-pane>
          </el-tabs>
        </div>
      </div>
     
      <div ref="contentBox" class="column2" @scroll='showOut'>
          <div class="content">
              <div ref="test1">1</div>
              <div ref="test2">2</div>
              <div ref="test3">3</div>
              <div ref="test4">4</div>
              <div ref="test5">5</div>
              <div ref="test6">6</div>
              <div ref="test7">7</div>

          </div>
      </div> -->
    <!-- <div @click="changStatus">
      <span>{{EnumList['FAIL']}}</span>
      <span v-for="(item, index) in EnumList" :key="index">
      
      </span>
    </div>

    <div class="tmpA">
      <h3>this is A</h3>
      <input type="text" v-model="message"/>
      <p>收到来自{{comp}}的信息:{{messageFromComp}}</p>
      <TmpB :messageFromA="message" @keyup="receive"/>
      <Test name="这是一个测试"></Test>
    </div>

    <div>
      <p>{{msg.date}}</p>
      <button @click="c">点击</button>
    </div> -->
  </div>
</template>
<script>
import TmpB from './tmpB'
import Test from './test1'
import test1 from './mixin/test1'
import markdownRender from '@/views/components/markdownRender'
import DOMPurify from 'dompurify'
import { marked } from 'marked'
import hljs from 'highlight.js'
import child from './child.vue'
// 配置高亮
marked.setOptions({
  highlight: function (code, lang) {
    try {
      if (lang) {
        return hljs.highlight(code, { language: lang, class: 'hljs' }).value
      } else {
        return hljs.highlightAuto(code).value
      }
    } catch (error) {
      return code
    }
  },
  gfmtrue: true,
  breaks: true,
})
// import getUserData from '../../utils/getUserData'
export default {
  name: 'Demo',
  components: {
    TmpB,
    Test,
    markdownRender,
    child,
  },
  watch: {
    order(old, newV) {
      if (this.order % 4 == 1) {
        this.str = 'By Punk!'
      } else if (this.order % 4 == 2) {
        this.str = 'looking for a job. '
      } else if (this.order % 4 == 3) {
        this.str = 'a front-end programmer.'
      } else {
        this.str = 'coding the web...'
      }
    },
  },
  mixins: [test1],
  data: () => {
    return {
      status: false,
      showCursor: true,
      message: '',
      messageFromComp: '',
      comp: '',
      data: {},
      msg: {
        date: new Date(),
      },
      words: [], //字母数组push，pop的载体
      str: 'By Punk', //str初始化
      letters: [], //str分解后的字母数组
      order: 1,
      textValeu:
        '好的，那我来给您写一个稍微难一些的猜数字游戏。\n\n代码如下：\n\n``` python\nimport random\n\nprint("欢迎来到猜数字游戏！")\nprint("我已经想好了一个1到100之间的整数，你可以来猜猜看。")\nprint("你有6次机会。")\n\nnumber = random.randint(1, 100)\nattempts = 0\n\nfor i in range(1, 7):\n print(f"第{i}次猜测：")\n guess = int(input())\n\n attempts += 1\n\n if guess < number:\n print("你猜测的数有些小了哦。")\n elif guess > number:\n print("你猜测的数有些大了哦。")\n else:\n print(f"恭喜您，在第{i}次猜测时，猜对了数字{number}！")\n break\n\n if attempts == 6:\n print("很遗憾，六次机会用完了。")\n print(f"正确数字为{number}。")\n\n```\n\n这次我们设置了一个6次的猜测机会，如果在6次之内没有猜对，游戏结束，并告诉玩家正确答案。',
      typingTexts: [],
      activeName: '1',
      strValue: '',
      // 启动打字机效果
      typingIndex: 0,
      // textValeu:  "纠正一个错误的方法有很多，",
    }
  },
  computed: {
    changerStatus() {
      return (this.status = true)
    },
    EnumList() {
      return {
        INIT: this.$t('dc:初始化'),
        PROCESSING: this.$t('dc:处理中'),
        SUCCESS: this.$t('dc:处理完成'),
        FAIL: this.$t('dc:处理失败'),
        0: this.$t('dc:初始化'),
        2: this.$t('dc:处理中'),
        3: this.$t('dc:处理完成'),
        4: this.$t('dc:处理失败'),
        i18n: {
          INIT: this.$t('dc:初始化'),
          PROCESSING: this.$t('dc:处理中'),
          SUCCESS: this.$t('dc:处理完成'),
          FAIL: this.$t('dc:处理失败'),
          0: this.$t('dc:初始化'),
          2: this.$t('dc:处理中'),
          3: this.$t('dc:处理完成'),
          4: this.$t('dc:处理失败'),
        },
      }
    },
  },
  mounted() {
    this.initEvent()
  },
  updated() {},
  methods: {
    handleClick(tab, event) {
      // const name = tab.nam
      // const height = this.$refs.name.scrollTop
      this.$refs.contentBox.scrollTop = this.$refs.test3.offsetTop
    },
    showOut(v) {
      // this.$refs.contentBox.scrollTop = v
    },
    addStr() {
      // this.words.push('asdad')
      // this.showCursor = true
      // let str = DOMPurify.sanitize(marked.parse("好的，那我来给您写一个稍微难一些的猜数字游戏。\n\n代码如下：\n\n``` python\nimport random\n\nprint(\"欢迎来到猜数字游戏！\")\nprint(\"我已经想好了一个1到100之间的整数，你可以来猜猜看。\")\nprint(\"你有6次机会。\")\n\nnumber = random.randint(1, 100)\nattempts = 0\n\nfor i in range(1, 7):\n print(f\"第{i}次猜测：\")\n guess = int(input())\n\n attempts += 1\n\n if guess < number:\n print(\"你猜测的数有些小了哦。\")\n elif guess > number:\n print(\"你猜测的数有些大了哦。\")\n else:\n print(f\"恭喜您，在第{i}次猜测时，猜对了数字{number}！\")\n break\n\n if attempts == 6:\n print(\"很遗憾，六次机会用完了。\")\n print(f\"正确数字为{number}。\")\n\n```\n\n这次我们设置了一个6次的猜测机会，如果在6次之内没有猜对，游戏结束，并告诉玩家正确答案。"))
      // let newStr = str.replace('<pre>','<pre class="hljs">')
      // this.typingTexts[0]=newStr;
      // this.typingTexts.push('asdasdasdasdas')
      // let newStr = DOMPurify.sanitize(marked.parse(this.textValeu))
      this.letters = this.textValeu.split('')
      //  setInterval(() => {
      //   setValue
      //  }, 200);
      for (let index = 0; index < this.letters.length; index++) {
        setTimeout(this.setValue(index), index * 100)
      }
    },
    setValue(i) {
      return () => {
        let str = this.letters[i]
        this.strValue = this.strValue + str
      }

      // return this.strValue += this.letters[i]
    },
    initEvent() {
      // this.printT1()
      this.begin()
      // getUserData().then( res => {
      //   let {status,data} = res
      // })
    },
    //开始输入的效果动画
    begin() {
      this.letters = this.str.split('')

      for (var i = 0; i < 20; i++) {
        setTimeout(this.write(i), i * 100)
      }
    },
    //开始删除的效果动画
    back() {
      let L = this.letters.length
      for (var i = 0; i < L; i++) {
        setTimeout(this.wipe(i), i * 50)
      }
    },
    //输入字母
    write(i) {
      return () => {
        let L = this.letters.length
        this.words.push(i)
        /*如果输入完毕，在2s后开始删除*/
        // if(i==L-1){
        //   setTimeout(function(){
        //     that.back();
        //   },2000);
        // }
      }
    },
    //擦掉(删除)字母
    wipe(i) {
      return () => {
        this.words.pop(this.letters[i])
        /*如果删除完毕，在300ms后开始输入*/
        if (this.words.length == 0) {
          this.order++
          let that = this
          setTimeout(function () {
            that.begin()
          }, 300)
        }
      }
    },
    printT1() {},
    receive(e) {
      this.comp = e.target.name
      this.messageFromComp = e.target.value
    },
    scrollToNum() {
      this.showOut()
    },
    changStatus() {
      this.status = true
    },
    c() {
      this.msg.date.setDate(this.msg.date.getDate() + 1)
      this.msg.date = new Date(this.msg.date)
    },
  },
}
</script>
<style lang="scss">
.typer {
  margin-top: 2%;
  box-sizing: border-box;
}
.typer .typer-content {
  font-weight: bold;
  font-size: 50px;
  display: flex;
  flex-direction: row;
  letter-spacing: 2px;
}
.typer-dynamic {
  position: relative;
}
.cut {
  color: #e84d49;
}
.typer-cursor {
  position: absolute;
  height: 100%;
  width: 3px;
  top: 0;
  right: -10px;
  background-color: #e84d49;
  animation: flash 1.5s linear infinite;
}
</style>
