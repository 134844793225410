<template>
  <div>
    <el-dialog
      :visible.sync="show"
      :width="dWith"
      top="15%"
      :title="titleMsg"
      :close-on-click-modal="false"
      class="acctount-detail-dialog"
      :class="otherClass"
      @close="closed"
    >
      <div :class="fontClass" >{{contentMsg}}</div>
      <div slot="footer" >
        <el-button type="primary"  class="btn-green" @click="submit">OK</el-button>
      </div>
      
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'applyDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    content: String,
    className: String,
    fontClass:{
      type:String,
      default:'fontB6'
    },
    dWith: {
      type: String,
      default: '380px'
    },
    noClass: String
  },
  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },
  data() {
    return {
      show: this.visible,
      titleMsg: this.title || this.$t('dc:提醒'),
      contentMsg: this.content || this.$t('dc:你的流量用完了'),
      otherClass: this.className,
    
    }
  },
  methods: {
    submit() {
      this.$emit('confrom');
    },
    closed() {
      this.$emit('close')
      this.show = false
    }
  }
}
</script>
<style lang="scss" >
.acctount-detail-dialog .el-dialog__body div.fontB6{
    word-break: break-word;
    font-weight: 600 !important;
  }
.acctount-detail-dialog .el-dialog__body div.fontB1{
    word-break: break-word;
    font-weight: 100 !important;
  }
</style>


