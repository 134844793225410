<template>
  <div class="sso-management">
    <poc-container class="sm-container">
      <template slot="logo">
        <img :src="logoImg" style="height: 36px" />
        <img :src="ssoLogoImg" style="height: 26px; margin-bottom: -10px" />
      </template>
      <HeaderRightGroup slot="rightGroup" :showMsg="true" />
      <MenuLeft slot="menu" />
      <div class="sso-management-body">
        <router-view />
      </div>
    </poc-container>
  </div>
</template>

<script>
import logoImg from '@/assets/img/new_logo.png'
import ssoLogoImg from '@/assets/img/ssolog.png'
import HeaderRightGroup from '@/components/HeaderRightGroup'
import MenuLeft from '@/components/MenuLeft'
import { mapActions } from 'vuex'
export default {
  name: 'DeployHome',
  components: {
    HeaderRightGroup,
    MenuLeft,
  },

  data() {
    return {
      logoImg,
      ssoLogoImg,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(['updateUserInfo', 'updataAgencyList', 'updataClientList']),
    initData() {
      this.getUserInfo()
      this.getUserAgencyList()
      this.getUserClientList()
    },
    // 获取产品列表
    async getUserAgencyList() {
      let { status, data } = await $Api.common.getAllAgencyList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) this.updataAgencyList(data)
    },
    // 获取产品列表
    async getUserClientList() {
      let { status, data } = await $Api.common.getAllClientList()
      if (status === this.$RESPONSE_STATUS.SUCCESS) this.updataClientList(data)
    },
    async getUserInfo() {
      let { status, data } = await $Api.user.getUserInfo()
      if (status === this.$RESPONSE_STATUS.SUCCESS) this.updateUserInfo(data)
    },
  },
}
</script>

<style lang="scss">
.sso-management {
  height: 100%;
  .sm-container {
    background: $base-bg-color;
  }

  .logTitle {
    line-height: 1;
    display: table;
    padding-top: 10px;
    padding-bottom: 0;
    font-weight: 400;
    color: $--color-primary;
  }
  .sso-management-body {
    padding: 0 $MP16;
  }
  .product-name {
    font-family: 'tradeGothicLHExtended' !important;
    font-weight: bold;
    font-size: 34px;
  }
}
.poc-container .poc-header {
  align-items: flex-end;
  align-items: center;
}
// .poc-container .poc-header .poc-header-logo{
//   display: flex;
//   align-items: flex-end;
//   margin-bottom: 20px;
// }
.common-page {
  padding: $MP16 0;
}
</style>
