const { VUE_APP_BASEURL} = process.env;
export default {
   /**
   * 提交用户反馈
   */
    submitFeedBack(params) {
      return $Request.post(`${VUE_APP_BASEURL}/api/security/user/feedback/submit`,params);
    },
    /**
     * 获取反馈列表
     */
    getFeedbackList(data) {
      return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/feedback/list`,{params:data});
    },
    /**
     * 跟新反馈状态
     */
    updStatus(params) {
      return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/feedback/upd-status`,params);
    },
    /**
     * 反馈详情
     */
    getFeedbackDetail(data) {
      return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/feedback/details`,{params:data});
    }
}