import { render, staticRenderFns } from "./goLinkDialog.vue?vue&type=template&id=486b6e5b"
import script from "./goLinkDialog.vue?vue&type=script&lang=js"
export * from "./goLinkDialog.vue?vue&type=script&lang=js"
import style0 from "./goLinkDialog.vue?vue&type=style&index=0&id=486b6e5b&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports