const { VUE_APP_BASEURL } = process.env
export default {
  /**
   *  获取项目类表
   */
  applist() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/app/list`)
  },
  /**
   * 获取用户权限
   */
  applyOption() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/app/applyOption`)
  },
  /**
   * 申请权限
   */
  apply(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/app/apply`, { params: data })
  },
  /**
   * 添加账户
   */
  addAccount(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user/add-account`, params)
  },

  /**
   * 获取品牌列表
   */
  getBrandList() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/acc-user/brand`)
  },
  /**
   * 获取品牌代理列表
   */
  getAgencyList() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/acc-user/agency`)
  },
  /**
   * 获取产品列表
   */
  getAppList(data) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/app/list`, { params: data })
  },
  /**
   * 添加新的产品
   */
  addApp(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/app/add`, params)
  },
  /**
   * 修改状态
   */
  updateStatus(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/app/update-status`, params)
  },
  /**
   *
   * 获取产品列表
   */
  optionList() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/app/option-list`)
  },
  /**
   * 获取产品基本信息
   */
  getProductMsg(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/role/option-list-by-project`, {
      params: { projectId: id },
    })
  },
  /**
   * 根据用户获取权限列表
   */
  getRoleAccessInfo(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/user/role-access-info`, {
      params: { userId: id },
    })
  },
  /**
   * 用户分配角色
   */
  assignRole(params) {
    return $Request.post(`${VUE_APP_BASEURL}/api/security/admin/user/assign-role`, params)
  },
  /**
   * 获取新版的列表
   */
  getListByType(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/app/v2/list-by-type`, {
      params: { typeId: id },
    })
  },
  /**
   * 首页常用的列表
   */
  getListMyFavor() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/app/v2/list-my-favor`)
  },
  /**
   * 获取团队应用列表
   */
  getListByTeam() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/app/v2/list-by-team`)
  },
  /**
   * 获取应用详情
   */
  getListInfo(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/app/v2/list-info`, {
      params: { appId: id },
    })
  },
  /**
   * 获取资源详情列表
   */
  getResourceList() {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/resource/list`)
  },
  /**
   * 根据产品获取模块列表
   */
  getModuleListByProduct(id) {
    return $Request.get(`${VUE_APP_BASEURL}/api/security/admin/module/option-list`, {
      params: { projectId: id },
    })
  },
}
