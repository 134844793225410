
<template>
  <div class="user-apply">
    <poc-container class="sm-container">
      <template slot="logo">
        <img :src="logoImg" style="height:36px;"/>
        <img :src="ssoLogoImg" style="height:33px;"/>
      </template>
      <HeaderRightGroup slot="rightGroup" :showMsg="showMsg"/>
      <div class="user-apply-body">
        <router-view />
      </div>
    </poc-container>
  </div>
</template>

<script>
import logoImg from '@/assets/img/log.png';
import ssoLogoImg from '@/assets/img/ssolog.png'
import HeaderRightGroup from '@/components/HeaderRightGroup';
export default {
  name: 'Home',

  components: {
    HeaderRightGroup,
  },
  
  data() {
    return {
      logoImg,
      ssoLogoImg,
      showMsg:false
    };
  },
}
</script>

<style lang="scss">
.user-apply {
  height: 100%;
  .sm-container {
    background: $base-bg-color;
  }
  .user-apply-body {
    padding: 0 $MP16;
  }
  .product-name {
    font-family: "tradeGothicLHExtended" !important;
    font-weight: bold;
    font-size: 20px;
  }
}

.common-page {
  padding: $MP16 0;
}
</style>

