const ProductList = () => import('@/views/operation/ProductList');
const AccessList = () => import('@/views/operation/AccessList');
const GroupList = () => import('@/views/operation/GroupList');
const ModuleList = () => import('@/views/operation/ModuleList');
const AnnouncementList = () => import('@/views/operation/AnnouncementList')
// const RoleList = () => import('@/views/operation/RoleList');
export default {
  routes: [
    {
      path: 'product-list',
      name: 'OperationAccess.ProductList',
      component: ProductList,
      meta: {
        title: 'Product List'
      },
    },
    {
      path: 'access-list',
      name: 'OperationAccess.AccessList',
      component: AccessList,
      meta: {
        title: 'Access List'
      },
    },
    {
      path: 'Module-list',
      name: 'OperationAccess.ModuleList',
      component: ModuleList,
      meta: {
        title: 'Module List'
      },
    },
    // {
    //   path: 'role-list',
    //   name: 'OperationAccess.RoleList',
    //   component: RoleList,
    // },
    {
      path: 'group-list',
      name: 'OperationAccess.GroupList',
      component: GroupList,
      meta: {
        title: 'Group List'
      },
    },
    {
      path: 'announcement-list',
      name: 'OperationAccess.AnnouncementList',
      component: AnnouncementList,
      meta: {
        title: 'Announcement List'
      },
    },
  ],
};
